import React from 'react'
import missing from "../../img/svg/missing.svg"
import { Container, Image } from 'react-bootstrap'

export default function NotFound({ job, location }) {
    return (

        <Container fluid >
            <div className='m-5'>
                <h1>Oops, No Jobs Found <span>&#128543;</span></h1>
                <Image src={missing} fluid width={'50%'} />
                <Container>
                    <h3 className='text-lg text-start lg:w-5/12 w-11/12'>We're sorry, but it looks like there are currently no job openings that match your search criteria for {job} in {location}.</h3>
                    <h2 className='text-2xl text-primary lg:w-5/12 w-11/12'>What can you do next?</h2>
                    <ul className='flex flex-col items-start gap-y-2 lg:w-5/12 w-11/12'>
                        <li>
                            <h4 className='text-start'><span className=' font-semibold'>Broaden Your Search:</span> Try searching for a different job title, location, or using more general keywords. Sometimes, a broader search can yield more results.
                            </h4>
                        </li>
                        <li>
                            <h4 className='text-start'><span className=' font-semibold'>Explore Other Locations:</span> If you're open to relocating or remote work, you might find more opportunities in nearby areas.
                            </h4>
                        </li>
                        <li>
                            <h4 className='text-start'><span className=' font-semibold'>Check Back Later:</span> New jobs are being uploaded regularly so make sure to check again next time.
                            </h4>
                        </li>
                    </ul>
                    <p className='text-start lg:w-5/12 w-11/12'>
                        We understand that job hunting can be challenging, but don't give up! New opportunities arise frequently, so keep exploring, and you'll find the perfect job for you.
                    </p>
                </Container>

            </div>
        </Container>


    )
}