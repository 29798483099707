import { Outlet } from "react-router-dom";
import NavigationBar from '../navigation_bar';
import FooterQuork from "../footer";
import Container from 'react-bootstrap/Container';

export default function Layout(user) {
    return (
        <>
            <NavigationBar user={user} ></NavigationBar>
            <main>
                <Outlet />
            </main>
            <Container fluid className="justify-content-center flex-1" />
            <FooterQuork />
        </>
    )
}