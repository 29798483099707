import React, { createRef, useEffect, useState } from "react";
import StatusCard from "./status_card";
import { Stack } from "react-bootstrap";
import { Button, Checkbox, Input, Form, Steps, Textarea, Tooltip } from "react-daisyui";
import SimpleJobStage from "./simple_job_stage";
import {
  CalendarMonth,
  CashStack,
  HandThumbsDown,
  PencilSquare,
  X,
} from "react-bootstrap-icons";
import Construction from "./construction";
import {
  setComments,
  setInterview,
  setNewOffer,
} from "../utils/job_tracker_recorder";
import JobDescriptor from "./job_descriptor";

export default function Tracker({ user, job, status, close }) {
  const notesRef = createRef();
  const dateRef = createRef();
  const timeRef = createRef();
  const moneyRef = createRef();
  const bonusRef = createRef();
  const stockRef = createRef();
  const startRef = createRef();
  const pensionRef = createRef();
  const insuranceRef = createRef();
  const [hideContent, setHideContent] = useState(false);
  const [notes, setNotes] = useState(false);
  const [appointment, setAppointment] = useState(false);
  const [offer, setOffer] = useState(false);

  const [statuses, setStatuses] = useState([]);
  const [stage, setStage] = useState("");
  useEffect(() => {
    if (job) {
      let tempStatus = Object.values(job.applicationStatus);
      tempStatus = tempStatus.map((x, i) => {
        x["applicationDate"] = new Date(x.when);
        return x;
      });

      let orderedStatuses = tempStatus.sort(
        (a, b) => Number(a.applicationDate) - Number(b.applicationDate)
      );
      setStatuses(orderedStatuses);
      setStage(orderedStatuses[orderedStatuses.length - 1]);
      // setStage('interested');
    }
  }, [job]);

  const hideAll = () => {
    setNotes(false);
    setAppointment(false);
    setOffer(false);
    setHideContent(false);
  };


  const offerSummary = (offer) => {
    return <div className="flex flex-col">
      {offer.comments && <div>
        {Object.keys(offer.comments).map(k => <div className="py-2">{k} : {offer.comments[k].toString()}</div>)}
        {offer.stage === "offer" && <div className="flex flex-row-reverse gap-2">
        <Button color="secondary">Reject</Button>
        <Button color="primary">Accept</Button>
        </div>}
      </div>
      }
    </div>
  }

  const offerScreen = () => (
    <div className="grid grid-cols-2  gap-1 font-sans">
      <div className="form-control w-full max-w-xs">
        <label className="label">
          <span className="label-text">Base Salary</span>
        </label>
        <Input ref={moneyRef} />
      </div>
      <div className="form-control w-full max-w-xs">
        <label className="label">
          <span className="label-text">Bonus</span>
        </label>
        <Input ref={bonusRef} />
      </div>

      <div className="form-control w-full max-w-xs">
        <label className="label">
          <span className="label-text">Stock allocation</span>
        </label>
        <Input ref={stockRef} />
      </div>

      <Form className="w-64 p-4">
        <Form.Label title="Start Date">
          <input
            ref={startRef}
            className="input input-bordered w-full max-w-xs"
            type="date"
            min="2018-01-01"
            max="2025-12-31"
          />
        </Form.Label>
      </Form>

      <Form className="w-64 p-4">
        <Form.Label title="Health Insurance">
          <Checkbox ref={insuranceRef} />
        </Form.Label>
      </Form>

      <Form className="w-64 p-4">
        <Form.Label title="401">
          <Checkbox ref={pensionRef} />
        </Form.Label>
      </Form>
      <div></div>
      <div className="place-content-end">
        <Button
          color="primary"
          onClick={() => {
            setNewOffer(
              user,
              job.jobReqId,
              bonusRef.current.value,
              moneyRef.current.value,
              stockRef.current.value,
              insuranceRef.current.checked,
              pensionRef.current.checked,
              startRef.current.value
            );
            hideAll();
            close();
          }}
        >
          Save
        </Button>
      </div>
    </div>
  );

  return (
    <>
      <div className="flex gap-10 h-full">
        <div className="flex-none h-[25rem]  overflow-auto">
          <Steps vertical className="py-10">
            {statuses
              .map((x, i) => (
                <Steps.Step
                  value={i + 1}
                  color={
                    statuses.length - 1 === i ||
                    ["accepted", "rejected"].indexOf(x.stage) !== -1
                      ? "neutral"
                      : "success"
                  }
                >
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={(e) => setStage(x)}
                  >
                    {x.stage.charAt(0).toUpperCase() + x.stage.slice(1).replaceAll("_", " ")}
                    <p className="text-xs italic">
                      {new Date(x.when).toDateString()}
                    </p>
                  </div>
                </Steps.Step>
              ))
              .reverse()}
          </Steps>
        </div>

        <div className="flex-1 h-[25rem] overflow-auto">
          {status === "active" && (
            <div className="flex hidden justify-end">
              <div className="flex items-center gap-2">
                <Tooltip position="bottom" message="Add Comments">
                <Button
                  shape="circle"
                  onClick={() => {
                    setNotes(true);
                    setAppointment(false);
                    setOffer(false);
                    setHideContent(true);
                  }}
                  className="join-item"
                  color="ghost"
                >
                  <PencilSquare size={20} />
                </Button>
                </Tooltip>

                <Tooltip position="bottom" message="New Interview">
                <Button
                  onClick={() => {
                    setNotes(false);
                    setAppointment(true);
                    setOffer(false);
                    setHideContent(true);
                  }}
                  shape="circle"
                  className="join-item"
                  color="ghost"
                >
                  <CalendarMonth size={20} />
                </Button>
                </Tooltip>

                <Tooltip position="bottom" message="New Offer">
                <Button
                  onClick={() => {
                    setNotes(false);
                    setAppointment(false);
                    setOffer(true);
                    setHideContent(true);
                  }}
                  shape="circle"
                  className="join-item"
                  color="ghost"
                >
                  <CashStack size={20} />
                </Button>
                </Tooltip>

                <Tooltip message="Don't like (working)" position="left">
                <Button shape="circle" className="join-item disabled:bg-white" color="ghost" disabled>
                  <HandThumbsDown size={20} />
                </Button>
                </Tooltip>
              </div>
            </div>
          )}

          <div className="hidden">
            {hideContent ? (
              <div>
                <div className="flex flex-row-reverse">
                  <Button
                    shape="circle"
                    color="ghost"
                    onClick={() => {
                      hideAll();
                    }}
                  >
                    <X size={20}></X>
                  </Button>
                </div>
                {notes && (
                  <div className="flex flex-col w-full component-preview  gap-2 font-sans">
                    <label className="label">
                      <span className="label-text">Notes</span>
                    </label>
                    <Textarea
                      ref={notesRef}
                      style={{ width: "100%", height: "120px" }}
                    />
                    <div className="flex flex-row-reverse w-full">
                      <Button
                        color="primary"
                        onClick={(e) => {
                          setComments(
                            user,
                            job.jobReqId,
                            notesRef.current.value
                          );
                          hideAll();
                          close();
                        }}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                )}
                {appointment && (
                  <div className="flex flex-col component-preview  gap-2 font-sans">
                    <div className="flex gap-4">
                      <label className="label">
                        <span className="label-text">Date</span>
                      </label>
                      <input
                        ref={dateRef}
                        className="input input-bordered w-full max-w-xs"
                        type="date"
                        min="2018-01-01"
                        max="2025-12-31"
                      />
                      <label className="label">
                        <span className="label-text">Time</span>
                      </label>
                      <input
                        className="input input-bordered w-full max-w-xs"
                        type="time"
                        ref={timeRef}
                      />
                    </div>

                    <div className="flex flex-row-reverse w-full">
                      <Button
                        color="primary"
                        onClick={() => {
                          setInterview(
                            user,
                            job.jobReqId,
                            dateRef.current.value,
                            timeRef.current.value
                          );
                          hideAll();
                          close();
                        }}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                )}
                {offer && offerScreen()}
              </div>
            ) : ["applied", "not_good_offer", "notes", "interview"].indexOf(
                stage.stage
              ) !== -1 ? (
              <SimpleJobStage
                stage={stage}
                total_stages={statuses.length}
                active={status === "active"}
                notes={stage.comments ? stage.comments.content : undefined}
              />
            ) : stage.stage == "interested" ? (
              <JobDescriptor
                isDashboard={true}
                job={job}
                likedJob={true}
                user={user}
              />
            ) : (
              offerSummary(stage)
            )}
            
          </div>

          <JobDescriptor
                isDashboard={true}
                job={job}
                likedJob={true}
                user={user}  
                appliedPosition={stage.stage !== "interested"}              
              />
        </div>
      </div>
    </>
  );
}
