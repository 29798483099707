import React, { useEffect, useState } from "react";
import Delete from "../../img/svg/X.svg"
import { Container, Form, Row, Col } from "react-bootstrap";
import { X, XCircle } from "react-bootstrap-icons";

export default function Skills(props) {

    const [skills, setSkills] = useState([]);
    const [skillInput, setSkillInput] = useState("");

    //if(Array.isArray(props.prefs.skills)) setSkills(props.prefs.skills)
    useEffect(() => {
        if (Array.isArray(props.prefs.skills)) {
            setSkills(props.prefs.skills)
        }
    }, [])

    const handleKeyDownSkill = (event) => {
        if (event.key === 'Enter') {
            // Here, we call the onSearch function and pass the value
            setSkillInput(skillInput);
            let skillString = skillInput.replace(";", ",");
            const skillsList = skillString.split(",");
            setSkills([...skillsList, ...skills]);
            setSkillInput("");
            props.prefs.skills = [...props.prefs.skills, ...skillsList];
            props.onEdit(props.prefs);
        }
    };

    const handleDeleteSkill = (key) => {
        let newValues;
        setSkills(oldValues => {
            newValues = oldValues.filter((_, i) => i !== key);
            console.log(newValues);
            props.prefs.skills = newValues;
            props.onEdit(props.prefs);
            return newValues;
        })
    }
    return (
        <>
            
            <h4>
                Tell us what you are great at:
            </h4>

            <Form.Label htmlFor="inputSkills">
                  Skills
                </Form.Label>
                <Form.Control
                  type="text"
                  value={skillInput}
                  onChange={(e) => {
                    setSkillInput(e.target.value);
                  }}
                  placeholder="You can add multiple skills separated by comma"
                  onKeyDown={handleKeyDownSkill}
                  id="inputSkills"
                  aria-describedby="inputSkills"
                />
            <Container className="mt-3" fluid>
            <div className="d-flex justify-content-center">
                <Row>
                
                {skills.map((item, index) => (
                    <Col sm>
                        <span  key={index} className="d-flex flex-row quork-pill mt-2">
                            <div className="flex-grow-1 text-truncate">
                                
                            {item}
                            </div>
                             
                            <XCircle size={'20px'}  className="mt-1" style={{cursor: "pointer"}} onClick={() => handleDeleteSkill(index)}></XCircle>
                        </span>
                    </Col>
                    
                ))}
                </Row>
                
            </div>
            </Container>
            
          
        </>
    )
}