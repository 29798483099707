import { Outlet } from "react-router-dom";
import Logo from "../../img/logo.png"

export default function AuthLayout() {
    return (
        <div className="flex flex-col justify-center align-center items-center min-h-screen h-screen bg-slate-50">
            <main className="w-full">
                <div className="flex flex-col justify-center items-center w-full">
                    <Outlet />
                </div>

            </main>

        </div>
    )
}