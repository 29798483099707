import React from 'react'
import logo from '../img/svg/missing.svg'
import { Container, Row, Col, Image } from 'react-bootstrap';

export default function Home() {
  return (
    <>

        <Container fluid>
          <Row className='m-5'>
              <Col xs={12} md={4} className={'p-4'}>
                <Image src={logo} fluid />
              </Col>
              <Col md={8} className='p-4'>
                <h1 className='text-xl md:text-3xl font-extrabold text-center px-10 py-5 lg:py-10' style={{color: "#4439e0"}}>The intelligent job finder that curates jobs just for you based on what is important to you</h1>
                <div className='px-10 py-15 md:text-xl text-center'>
                  Want to only search for companies that offer free food and a ping pong table? Only those with above 6% 401(k) match and amazing insurance policies? We&apos;ve got you. If they matter to you, they matter to us.
                </div>
             </Col>
          </Row>
        </Container>

        <Container fluid>

        <div className="col gy-5 m-5">
            <div className="row gy-5 icon-boxes">

              <div className="col-lg-4">
                <div className="icon-box">
                  <i className="bi bi-lightbulb"></i>
                  <h3>AI Powered</h3>
                  <p>Discover curated jobs from top sources. Quork's AI learns from your choices and suggests roles for you. Simplifying your job search</p>
                </div>
              </div>
              

              <div className="col-lg-4">
                <div className="icon-box">
                  <i className="bi bi-lightning"></i>
                  <h3>Easy to use</h3>
                  <p>Frictionless setup. Tell us about yourself, your desired role, skills, and ideal company. Let's find your perfect match.</p>
                  <br />
                </div>
              </div> 

        
              <div className="col-lg-4">
                <div className="icon-box">
                  <i className="bi bi-compass"></i>
                  <h3>Track your applications</h3>
                  <p>With your help, Quork will keep track of the jobs you like and your applications. You'll see all your applications in one place, making your decision a breeze!</p>
                </div>
              </div> 
            

            </div>
          </div>
          
        </Container>         
          
          

      {/* <div className='flex flex-wrap justify-center px-5 py-15 gap-1' >
       
          {features.map((x, i) =>
            <div className='px-10 w-[28rem]'> 
            <Card className='shadow-xl border-slate-200'>
             
              <Card.Body className='' >
                <Card.Title className='text-xl' style={{color: '#4439e0'}}>
                  {x.title}
                </Card.Title>
                
                <ReactSVG style={{width:"60px"}}  src={x.icon}/>
                
                
                {x.text}
              </Card.Body>
            </Card>
            </div>
          )}
      </div> */}
    </>
  );
}
