import React from 'react'
import { Navbar, Container, Nav } from 'react-bootstrap'

export default function FooterQuork() {
  return (

    <Navbar bg="light" fixed="bottom">
        <Container>
          <Navbar.Brand href="/">
            <h4 className="brand-footer">Quork<span className="brand-dot">.</span></h4>
          </Navbar.Brand>
          <Nav className="me-auto">
            {/* <Nav.Link href="https://ai.quork.me">AI</Nav.Link> */}
            <Nav.Link href="https://www.quork.me/index.html#about">About</Nav.Link>
            <Nav.Link href="/contact">Contact</Nav.Link>
            <Nav.Link href="/terms">Terms</Nav.Link>
            <Nav.Link href="/privacy">Privacy</Nav.Link>
          </Nav>
        </Container>
      </Navbar>

  )
}
