import React from "react";

import banner from './../img/banner.jpg'
import { Container } from "react-bootstrap";

export default function About() {
    return<div className="">
        <>

        <div className="about" style={{backgroundImage: 'url(' + banner + ')'}}>
         
          
            <Container className="mt-5">
              <h1>Hello there</h1>
              <p className="mb-2">
              We started Quork because we found a place where we belong.  A place where we enjoy what we do and we enjoy the people we work with.
      We all care for our families and we know to put them above all else all the time.
      And we thought, "Wouldn’t it be great if we can help others find a workplace where they belong? Where they can be themselves and know that they bring value."
      At Quork, we want to help you find a workplace where you belong. This is our why.
              </p>
              {/* <button className="btn btn-primary">Get Started</button> */}
            </Container>
   
        </div>  
</>
   </div>
}