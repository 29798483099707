import React, { useEffect, useState } from "react";
import Position from "./position";
import Perks from "./perks";
import Skills from "./skills";
import { updatePrefs } from "../../utils/user_prefs";
import { Loading } from "react-daisyui";
import { Container, Spinner, Form, Stack } from "react-bootstrap";

//Props
//user: user object with uid etc...
//userPrefs: user preferences fetched from the rtdb

export default function JobPreferences(props) {
  const [tab, setTab] = useState(1);
  const [linkedinValid, setLinkedinValid] = useState(true);
  const [slider, setSlider] = useState(
    "w-1/3 h-full rounded-full bg-secondary absolute left-[17%] transition-all"
  );
  const [userPrefs, setUserPrefs] = useState(props.userPrefs);
  const btnStd = "btn w-1/3 h-full join-item bg-transparent text-text-black";
  const btnSelected =
    "btn w-1/3 h-full join-item bg-transparent text-secondary";

  const updateUserPrefs = (prefs) => {
    if (props.loading) return;
    setUserPrefs(prefs);
    //console.log(userPrefs);
    updatePrefs(props.user, prefs);
  };

  function isValidString(str) {
    // Regular expression pattern to match a valid string
    const pattern = /^[a-zA-Z0-9_\-./:]+$/;
  
    // Test the input string against the pattern
    return pattern.test(str);
  }

  const handleTabChange = (event) => {
    const btnText = event.currentTarget.textContent;
    // if (btnText === "My Dream Job") {
    //     setTab(1);
    //     setSlider("w-1/3 h-full rounded-full bg-secondary absolute left-0 transition-all");
    //     updateUserPrefs(userPrefs)

    // }
    if (btnText === "My Superpowers") {
      setTab(1);
      setSlider(
        "w-1/3 h-full rounded-full bg-secondary absolute left-[17%] transition-all"
      );
      updateUserPrefs(userPrefs);
    }
    if (btnText === "What's Important to Me") {
      setTab(2);
      setSlider(
        "w-1/3 h-full rounded-full bg-secondary absolute left-1/2 transition-all"
      );
      updateUserPrefs(userPrefs);
    }
  };

  return (
    <>
      <Container className="mt-2">
        {props.loading ? (
          // <div className="h-full w-full my-auto mx-auto">
          //     <Loading variant="bars" color="primary" size="lg" />
          // </div>
          <Spinner animation="grow" />
        ) : (
          <div className="icon-box">
            <Stack gap={5}>
              <div>
                <h4>About you:</h4>
                <Form.Group>
                <Form.Label htmlFor="inputLinkedin">
                  LinkedIn Profile
                </Form.Label>
                <Form.Control
                  type="text"
                  value={userPrefs.linkedinProfile}
                  isValid={userPrefs.linkedinProfile !== "" ? linkedinValid : undefined}
                  isInvalid={!linkedinValid}
                  onChange={(e) => {
                    e.preventDefault();

                    setLinkedinValid(isValidString(e.target.value))

                    setUserPrefs({
                      ...userPrefs,
                      linkedinProfile: e.target.value,
                    });
                  }}
                  onBlur={(e) => {
                    if(isValidString(userPrefs.linkedinProfile)){
                      userPrefs.linkedinProfile = e.target.value.includes(
                        "linkedin.com"
                      )
                        ? e.target.value
                            .replace("https://www.linkedin.com/in", "")
                            .split("/")[1]
                        : e.target.value.trim();
                      updatePrefs(props.user, userPrefs);
                    }

                    
                  }}
                  id="inputLinkedin"
                  aria-describedby="inputLinkedin"
                />
                <Form.Text id="passwordHelpBlock" muted>
                  Modifications in linkedin profile will require logout + login
                </Form.Text>
                  <Form.Control.Feedback type='invalid'>
          Invalid characters in your profile url
      </Form.Control.Feedback>
      </Form.Group>
              </div>
              <div>
                <Skills prefs={userPrefs} onEdit={updateUserPrefs} />
              </div>

              <div>
                <Perks prefs={userPrefs} onEdit={updateUserPrefs} />
              </div>
            </Stack>
          </div>
        )}
      </Container>
    </>
  );
}
