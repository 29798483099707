import React, { useEffect, useState, useRef, useCallback } from "react";
import { ref, onValue } from "firebase/database";
import { db } from "../config/firebase";
import {
  ArrowLeft,
  GeoAlt,
  HandThumbsUpFill,
  House,
  HouseFill,
  Info,
  Plus,
  Trash,
  Trash2,
} from "react-bootstrap-icons";
import {
  Col,
  Row,
  Image,
  Container,
  Stack,
  Button,
  Modal,
} from "react-bootstrap";
import JobDescription from "../components/job_description";
import { deleteJob } from "../utils/job_tracker_recorder";

function Dashboard({ user }) {
  const [selectedJob, setSelectedJob] = useState(null);
  // const progression = ["liked", "active", "closed"];
  // const [refresh, setRefresh] = useState(false);
  const [jobStatus, setJobStatus] = useState({
    rejectedJobs: [],
    acceptedJobs: [],
    inProgressJobs: [],
    likedJobs: [],
  });

  const [toBeDeleted, setToBeDeleted] = useState(null);
  const refDelete = useRef < HTMLDialogElement > null;
  const handleShowDelete = useCallback(() => {
    refDelete.current?.showModal();
  }, [refDelete]);

  const loadJobs = () => {
    const userRef = ref(db, user.uid + "/jobs");
    onValue(userRef, (snapshot) => {
      let jobs = snapshot.val() ? Object.values(snapshot.val()) : [];
      jobs = jobs.filter(x => x.applicationStatus !== undefined)
      let rejectedJobs = [];
      let acceptedJobs = [];
      let inProgressJobs = [];
      let likedJobs = [];

      jobs.forEach((job) => {
        if (job.applicationStatus && job.applicationStatus !== null) {
          let statuses = Object.keys(job.applicationStatus).map(
            (x) => x.split("_")[0]
          );
          if (statuses.includes("applied")) {
            let statusAppliedKey = Object.keys(job.applicationStatus).filter(
              (x) => x.startsWith("applied")
            );
            job["applicationData"] =
              statusAppliedKey.length == 0
                ? ""
                : new Date(job.applicationStatus[statusAppliedKey[0]].when)
                    .toISOString()
                    .split("T")[0];
            inProgressJobs.push(job);
            jobs = jobs.filter((x) => job.jobReqId !== x.jobReqId);
          }
        }
      });

      jobs.forEach((job) => likedJobs.push(job));

      setJobStatus({
        rejectedJobs: rejectedJobs,
        acceptedJobs: acceptedJobs,
        inProgressJobs: inProgressJobs,
        likedJobs: likedJobs,
      });
    });
  };

  useEffect(() => {
    if (user) {
      loadJobs();
    }
  }, [user]);

  function JobCard({ job, i }) {
    return (
      <div key={i} className={`job-box job-box-dash`}>
        <Row >
          <Col
            xs={4}

  
            onClick={() => {
              setSelectedJob(job);
            }}
          >
            <Container  className="me-5">
              <Image src={job.image} width={'50px'} className="job-box-img" roundedCircle />
            </Container>
          </Col>
          <Col xs={8} >
            <Container fluid className="">
              <Stack gap={1}>
                <div
                  className="d-flex flex-row"
                  onClick={() => {
                    setSelectedJob(job);
                  }}
                >
                  <h4>{job.title} </h4>
                </div>

                <p
                  onClick={() => {
                    setSelectedJob(job);
                  }}
                >
                  {job.companyName ? job.companyName : job.company_name}
                </p>
                <Row
                  className="justify-content-between"
                  onClick={() => {
                    setSelectedJob(job);
                  }}
                >
                  <Col xs={6}>
                  {job.posted_on && (
                    <div className="primary">
                      {`Posted on: ${job.posted_on.split(" ")[0]}`}
                    </div>
                  )}
                  </Col>
                  <Col xs={6}>

                  {job.applicationData && (
                    <div className="green">
                      {`Applied by ${job.applicationData.split(" ")[0]}`}
                    </div>
                  )}
                  </Col>
                </Row>

                <div className="d-flex flex-row justify-content-between">
                  <div
                    className="d-flex flex-row job-box-location"
                    onClick={() => {
                      setSelectedJob(job);
                    }}
                  >
                    <GeoAlt />
                    <div className="align-middle">{job.location}</div>
                    {job.remote && " / Remote allowed"}
                  </div>

                  <Button
                    variant="secondary"
                    className="job-box-dash-button"
                    onClick={() => {
                      setSelectedJob(null);
                      setToBeDeleted(job);
                    }}
                  >
                    <Trash size={"12px"} />
                  </Button>
                </div>
              </Stack>
            </Container>
          </Col>
        </Row>
      </div>
    );
  }

  return (
    <div className=" p-10">
      <Modal
        show={toBeDeleted !== null}
        onHide={() => {
          setToBeDeleted(null);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Alert!!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {toBeDeleted !== null ? `${toBeDeleted.title}` : "Unknown"} will be
          removed, are you sure?
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="button-secondary"
            onClick={() => {
              setToBeDeleted(null);
            }}
          >
            Cancel
          </Button>
          <Button
            className="button-primary"
            onClick={() => {
              deleteJob(user, toBeDeleted.jobReqId);
              setToBeDeleted(null);
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {selectedJob && (
        <div className="icon-box">
          <JobDescription
            job={selectedJob}
            isTracked={true}
            user={user}
            quit={() => {
              setSelectedJob(null);
            }}
            callback={() => {
              setSelectedJob(null);
            }}
          />
        </div>
      )}

      {selectedJob == null && (
        <Container className="mt-3">
          <Row>
            <Col sm>
              <h4 className="brand-dot">
                Liked Jobs ({jobStatus.likedJobs.length})
              </h4>
              <div className="dashboard">
                <Stack gap={3}>
                  {jobStatus.likedJobs.map((x, i) => (
                    <div className="p-3">
                      <JobCard job={x} i={i} />
                    </div>
                  ))}
                </Stack>
              </div>
            </Col>
            <Col sm>
              <h4 className="brand-dot">
                Active Jobs ({jobStatus.inProgressJobs.length})
              </h4>
              <div className="dashboard">
                <Stack gap={3}>
                  {jobStatus.inProgressJobs.map((x, i) => (
                    <div className="p-3">
                      <JobCard job={x} i={i} />
                    </div>
                  ))}
                </Stack>
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
}

export default Dashboard;
