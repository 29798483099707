import React, { useEffect, useRef, useState } from 'react'
import { ref, onValue } from 'firebase/database'
import { db } from '../config/firebase';
import { Card, Container, Dropdown, Form, Row, Col, Spinner } from 'react-bootstrap';
import SearchBox from '../components/search_box';
import { CustomMenu } from '../components/custom_menu_location';
import Construction from '../components/construction';
import JobPreferences from '../components/preferences';
import { Loading } from 'react-daisyui';
// import { Chip } from '@trimbleinc/modus-react-bootstrap';


function Preferences({ user }) {

    const defaultPrefs = {
        companySize: "Any",
        latlng: "",
        linkedinProfile: "",
        location: "",
        perks: [],
        role: "",
        skills: [],
        internship: false
    }
    const myDreamedJob = useRef(null);
    const [userPrefs, setUserPrefs] = useState(defaultPrefs);
    const [fetchingData, setFetchingData] = useState(true)
    useEffect(() => {
        setFetchingData(true);
        if (user) {
            //console.log(user)
            const userRef = ref(db, user.uid + '/info');
            onValue(userRef, (snapshot) => {
                //console.log(snapshot.val())
                const newUserPrefs = { ...defaultPrefs, ...snapshot.val() }
                setUserPrefs(newUserPrefs);
                setFetchingData(false);
            });
        }
    }, [user]);

    if(fetchingData){
        return <Spinner animation="grow" />;
    }
    else{
        //console.log(userPrefs)
        return (
            <JobPreferences user={user} userPrefs={userPrefs} loading={fetchingData} />
        )
    }
    



}

export default Preferences