import { ref, set } from "firebase/database";
import { db } from "../config/firebase";
import { generateUUID } from "./string";


export const setComments = (user,jobReqId, text) => {
    set(ref(db, `${user.uid}/jobs/${jobReqId}/applicationStatus/notes_${generateUUID()}`), {
        when: new Date().toISOString(),
        stage: 'notes',
        order: 3,
        completed: true,
        comments: {content: text}
    });
}

export const addChat = (user, job, text) => {
    set(ref(db, user.uid + "/jobs/" + job.jobReqId + '/chat/'), text);
}

export const likeJob = (user, job) => {
    set(ref(db, user.uid + "/jobs/" + job.jobReqId), {...job, 'applicationStatus' :{}});
    set(
      ref(
        db,
        user.uid +
          "/jobs/" +
          job.jobReqId +
          "/applicationStatus/interested_" +
          generateUUID()
      ),
      {
        completed: true,
        order: 0,
        stage: "interested",
        when: new Date().toISOString(),
      }
    );
}

export const setInterview = (user, jobReqId, date, time) => {
    const theDate = new Date(date + ' ' + time);
    set(ref(db, `${user.uid}/jobs/${jobReqId}/applicationStatus/interview_${generateUUID()}`), {
        when: new Date().toISOString(),
        stage: 'interview',
        order: 4,
        completed: true,
        comments: {"Interview Date": theDate.toISOString() }
    });
}


export const setNewOffer = (user, jobReqId, bonus, money, stock, insurance, pension, start) => {
    set(ref(db, `${user.uid}/jobs/${jobReqId}/applicationStatus/offer_${generateUUID()}`), {
        when: new Date().toISOString(),
        stage: 'offer',
        order: 5,
        completed: true,
        comments: {
            "Bonus": bonus,
            "Pension Schema": pension,
            "Healthcare Insurance": insurance,
            "Offer": money,
            "Stock Option": stock,
            "Start Date": start
        } 
    });
}

export const setAcceptOffer = (user, jobReqId, bonus, money, stock, insurance, pension, start) => {
    set(ref(db, `${user.uid}/jobs/${jobReqId}/applicationStatus/accepted`), {
        when: new Date().toISOString(),
        stage: 'accepted',
        order: 6,
        completed: true
        // comments: {
        //     "Bonus": bonus,
        //     "Pension Schema": pension,
        //     "Healthcare Insurance": insurance,
        //     "Offer": money,
        //     "Stock Option": stock,
        //     "Start Date": start
        // } 
    });
}

export const setBadOffer = (user, jobReqId) => {
    set(ref(db, `${user.uid}/jobs/${jobReqId}/applicationStatus/not_good_offer_${generateUUID()}`), {
        when: new Date().toISOString(),
        stage: 'not_good_offer',
        order: 8,
        completed: true,
    });
}

export const setRejected = (user, jobReqId) => {
    set(ref(db, `${user.uid}/jobs/${jobReqId}/applicationStatus/rejected}`), {
        when: new Date().toISOString(),
        stage: 'rejected',
        order: 8,
        completed: true,
    });
}

export const deleteJob = (user, jobReqId) => {
    set(ref(db, `${user.uid}/jobs/${jobReqId}`), null);
}

export const applyJob = (user, jobReqId) => {
    const t = new Date();
    t.setSeconds(t.getSeconds() + 10);
    set(
      ref(
        db,
        user.uid +
          "/jobs/" +
          jobReqId +
          "/applicationStatus/applied_" +
          generateUUID()
      ),
      {
        completed: true,
        order: 1,
        stage: "applied",
        when: t.toISOString(),
      }
    );
}

