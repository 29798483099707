import React, { useState } from "react";

//props
//prefs : userPreferences from firebase ** Need to sort typing out
//

export default function Position(props) {
    
    const [role, setRole] = useState(props.prefs.role);
    const [location, setLocation] = useState(props.prefs.location);
    const [internship, setInternship] = useState(props.prefs.internship);

    const handleKeyDownLocation = (event) => {
        if (event.key === 'Enter') {
            // Here, we call the onSearch function and pass the value
            setLocation(location);
            props.prefs.location = location;
            
            props.onEdit(
                
                props.prefs
            )
        }
    };

    const handleKeyDownRole = (event) => {
        if (event.key === 'Enter') {
            // Here, we call the onSearch function and pass the value
            setRole(role);
            props.prefs.role = role;
            props.onEdit(
                props.prefs
            )
            
            
        }
    };

    

    const handleInternshipChange = (event) => {
        console.log(event.target.checked);
        setInternship(event.target.checked);
        props.prefs.internship = event.target.checked;
        props.onEdit(
            props.prefs
        )
    }

    

    return (
        <>
            <div className="my-6 flex justify-start flex-col items-start w-full ml-14">
                <h3 className="text-2xl text-primary">
                    What is your dream job?
                </h3>
            </div>
            <div className="flex flex-col md:flex-row w-full gap-3 md:justify-around mb-10">
                <input type="text"
                    id="Role"
                    onKeyDown={handleKeyDownRole}
                    value={role}
                    onChange={(e) => { setRole(e.target.value) }}
                    onBlur={(e)=>{
                        setRole(e.target.value);
                        props.prefs.role = role;
                        console.log(props.prefs)
                        props.onEdit(
                            props.prefs
                        )
                    }}
                    placeholder="Role"
                    className="rounded-full py-2 md:w-5/12 focus:border-secondary focus:ring focus:ring-secondary text-lg bg-slate-50 pl-2 shadow">
                </input>
                <input type="text"
                    id="Location"
                    value={location}
                    onKeyDown={handleKeyDownLocation}
                    onChange={(e) => { setLocation(e.target.value) }}
                    onBlur={(e)=>{
                        setLocation(e.target.value);
                        props.prefs.location = location;
                        props.onEdit(
                            props.prefs
                        )
                    }}
                    placeholder="Location"
                    className="rounded-full py-2 md:w-5/12 focus:border-secondary focus:ring focus:ring-secondary text-lg bg-slate-50 pl-2 shadow">
                </input>
            </div>
            <div className="mt-10 mb-5 flex justify-start flex-col items-start w-full ml-14">
                <h3 className="text-2xl text-primary">
                    Are you looking for an internship
                </h3>
            </div>
            <div className="form-control mb-10">

                <label className="relative inline-flex items-center cursor-pointer">
                    <input type="checkbox" checked={internship} onChange={handleInternshipChange} className="sr-only peer focus:border-secondary focus:ring focus:ring-secondary"></input>
                    <div className="w-14 h-7 bg-bg-indigo peer-focus:outline-none peer-focus:ring peer-focus:ring-secondary rounded-full peer  peer-checked:after:translate-x-[115%] peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all peer-checked:bg-primary"></div>
                    <span className="ml-3 text-xl font-medium text-text-black">Internship Program</span>
                </label>

            </div>
        </>

    )
}