import React from 'react'
import { Card } from 'react-bootstrap'

function StatusCard({status}) {
  return (
    <Card className='p-2 shadow'>
        <Card.Title>{status.stage}</Card.Title>
        <Card.Body>
        
        </Card.Body>
    </Card>
  )
}

export default StatusCard