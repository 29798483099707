import { ref, set, get, child, update, onValue } from "firebase/database";
import { db } from "../config/firebase";

export const updatePrefs = (user, params) => {
    console.log(params)
    update(ref(db,`${user.uid}/info`),{...params})
}

export const getPrefs = async (user) => {
    const res = await get(child(db, `${user.uid}/info/`))
    return res.toJSON()
}

export const getPrefsCallback = async (user, callback) => {
    const userRef = ref(db, user.uid + "/info");
    onValue(userRef, (snapshot) => {
        callback(snapshot);
    });
}

