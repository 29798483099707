import React, { useEffect, useState, useCallback, useRef } from "react";
import { X } from "react-bootstrap-icons";

import {
  likeJob,
  applyJob,
  setRejected,
  setAcceptOffer,
} from "../utils/job_tracker_recorder";
import { ref, onValue } from "firebase/database";
import { db } from "../config/firebase";

import ChatQuork from "./chat";
import {
  Col,
  Row,
  Stack,
  Image,
  Container,
  Button,
  Modal,
} from "react-bootstrap";

export default function JobDescription({
  job,
  isTracked,
  user,
  callback,
  quit,
}) {
  const [showCompanyDescription, setShowCompanyDescription] = useState(false);
  const [enableApply, setEnableApply] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [withAnswer, setWithAnswer] = useState(false);

  useEffect(() => {
    if (isTracked) {
      const jobRef = ref(
        db,
        user.uid + "/jobs/" + job.jobReqId + "/applicationStatus"
      );
      onValue(jobRef, (snapshot) => {
        if (snapshot.val() !== null) {
          let status = Object.keys(snapshot.val()).length;
          setEnableApply(status > 1);
          setWithAnswer(status > 2);
        }
      });
    }
  }, [isTracked]);

  const action = (like) => {
    if (!like) {
      setShowModal(true);
    } else {
      likeJob(user, job);
      callback();
    }
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        animation={true}
      >
        <Modal.Header closeButton>Alert</Modal.Header>
        <Modal.Body>
          Did you apply for {job.title} at {job.company_name}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowModal(false);
            }}
          >
            No
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              if (!isTracked) likeJob(user, job);
              applyJob(user, job.jobReqId);
              callback();
              setShowModal(false);
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="description-box">
        <div className="d-flex  flex-row justify-content-end p-1">
          <X
            size={"20px"}
            onClick={() => quit()}
            style={{ cursor: "pointer" }}
          />
        </div>
        <Row>
          <Col xs={12} lg={4}>
            <Stack gap={2} className=" ">
              <div className="company-logo-container d-flex justify-content-center">
                <a
                  href={job.company_url}
                  target="_blank"
                  className="company-logo-description"
                >
                  <Image
                    src={job.image}
                    roundedCircle
                    thumbnail
                    className="company-logo"
                  />
                </a>
              </div>
              <Container fluid>
                <div className="d-flex flex-row justify-content-center">
                  <h4 className="fw-bolder text-center">{job.title}</h4>
                </div>
                <div className="d-flex flex-row justify-content-center">
                  <h5 className="fw-bold text-center">
                    {job.companyName ? job.companyName : job.company_name}
                  </h5>
                </div>
                <div className="d-flex flex-row justify-content-center medium">
                  {`${job.location}${job.remote ? " / Remote allowed" : ""}`}
                </div>
                <div className="d-flex flex-row justify-content-center medium mt-3">
                  {user === null ? (
                    <>
                      <Button className="button-primary" href="/auth/login">
                        Log In or Register to Apply
                      </Button>
                    </>
                  ) : (
                    <>
                      <Stack direction={"horizontal"} gap={3}>
                        {!enableApply || !isTracked ? (
                          <>
                            <Button
                              disabled={isTracked}
                              className="button-secondary button-application"
                              onClick={() => action(true)}
                            >
                              Save
                            </Button>
                            <Button
                              disabled={enableApply}
                              className="button-primary button-application"
                              href={
                                job.job_url !== ""
                                  ? job.job_url
                                  : job.company_url
                              }
                              onClick={() => action(false)}
                              target="_blank"
                            >
                              Apply
                            </Button>
                          </>
                        ) : (
                          <>
                            {!withAnswer && (
                              <>
                                <Button
                                  className="button-secondary button-application"
                                  onClick={() => {
                                    setRejected(user, job.jobReqId);
                                    callback();
                                  }}
                                >
                                  No Luck
                                </Button>
                                <Button
                                  className="button-primary button-application"
                                  onClick={() => {
                                    setAcceptOffer(user, job.jobReqId);
                                    callback();
                                  }}
                                >
                                  Accepted
                                </Button>
                              </>
                            )}
                          </>
                        )}
                      </Stack>
                    </>
                  )}
                </div>
              </Container>

              <ChatQuork job={job} user={user} />
            </Stack>
          </Col>
          <Col lg={8}>
            <Container className="p-5">
              <div className="job-description-container">
                <h4 className="brand-dot">About this job:</h4>
                {job.job_description.includes("/>") ? (
                  <div
                    className="p-1 text-sm lg:text-base lg:p-2"
                    dangerouslySetInnerHTML={{
                      __html: job.job_description,
                    }}
                  />
                ) : (
                  job.job_description
                )}
                <h4 className="brand-dot mt-4">
                  About {job.companyName ? job.companyName : job.company_name}:
                </h4>
                <div>
                  {job.companyDescription
                    ? job.companyDescription
                    : job.company_description}
                </div>
              </div>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
}
