export const flat_button = `.btn-flat {
    background-color: rgb(81, 54, 197);
    color: white;
}
.btn-flat:hover {
    background-color: rgb(81, 54, 197);
    color: white;
}
`;

export const white_button = `.btn-white {
    background-color: white;
    border: #dee2e6 solid;
}
.btn-white:hover {
    border: #dee2e6 solid
}
`;

export const bubbles = `
.speech-bubble {
    position: relative;
    background-color: #f7f7f7;
    border: 0px solid #ccc;
    border-radius: 20px;
    padding: 10px;
  }


`;

