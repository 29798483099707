import React, {useState, useEffect} from 'react'
import { Form, Dropdown } from 'react-bootstrap'

function LocationControl() {
    const [value, setValue] = useState('');
    const [locations, setLocations] = useState([]);

    useEffect(() => {
        if(value.length > 4) {
            const dataFetch = async () => {
                const data = await (await fetch("http://ai.quork.ph:5055/city_list", {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({'text': value}),
                    })).json();
                
                setLocations(data);
            }

            dataFetch();
        }
        
    }, [value]);

    return (
        <>
            <Form.Control
                autoFocus
                className="mx-3 my-2 w-auto"
                placeholder="Type to search..."
                onChange={(e) => setValue(e.target.value)}
                value={value}
            />
            <ul className="list-unstyled">
                {locations.map((item, index) => {
                    return <Dropdown.Item key={index} eventKey={JSON.stringify(item)}>{`${item.name}, ${item.admin1_code}`}</Dropdown.Item>
                })}
            </ul>
        </>
    );
}

export default LocationControl