import React , {useEffect, useState} from 'react'
import SearchBox from '../components/search_box';
import { Container } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import {ref, onValue} from 'firebase/database'
import { db } from '../config/firebase';
import Tracker from '../components/tracker';

export default function Progress({user}) {
    let [searchParams] = useSearchParams();
    const [job, setJob] = useState(null);


    useEffect(() => {
        if(user){
            const userRef = ref(db, user.uid + '/jobs/' + searchParams.get('jobReqId'));
            onValue(userRef, (snapshot) => {
                setJob(snapshot.val());
            });
        }
    },[user, searchParams]);

    return (
        <>
            <SearchBox />
            <Container>
                {
                    job !== null ? <Tracker job={job} user={user} /> : "Loading..."
                }
            </Container>
        </>
    );
}
