// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from 'firebase/database'
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import {getAnalytics} from 'firebase/analytics'

const firebaseConfig = {
  apiKey: "AIzaSyBKCbMzoy6VIwzyLabFk5LIZ-yHULczxug",
  authDomain: "quork-2a787.firebaseapp.com",
  projectId: "quork-2a787",
  storageBucket: "quork-2a787.appspot.com",
  messagingSenderId: "260789277929",
  appId: "1:260789277929:web:3104570ebd9b4e7b530c78",
  measurementId: "G-X25EWH5VKL"
};

const app = initializeApp(firebaseConfig);
// Initialize Analytics and get a reference to the service
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getDatabase(app);
export const googleProvider = new GoogleAuthProvider();
