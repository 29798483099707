import React from 'react'
import { Card } from 'react-daisyui'

function SimpleJobStage({stage, }) {

  return (
    <div>
      <p className='text-2xl'>{stage && stage.stage.toUpperCase()}</p>
      <span className='text-sm'>{(new Date(stage.when)).toDateString()}</span>
      {stage.stage == "interview" ?
        <Card className='w-fit text-center border-2 border-slate-200'>
          <Card.Body>
            <p className='text-6xl'>{new Date(stage.comments['Interview Date']).getDay()}</p>
            <p className='text-3xl'>{(new Date(stage.comments['Interview Date'])).toLocaleString('en-US', {month: 'long'})}</p>
            <p className='text-3xl'>{new Date(stage.comments['Interview Date']).toLocaleTimeString('en-US', {timeStyle: 'short'})}</p>
          </Card.Body>
        </Card>
       :<p>{stage.comments && stage.comments.content}</p>}

    </div>
  )
}

export default SimpleJobStage