import React, { useState } from "react";
import {
  Google,
  EyeFill,
  EyeSlash,
  Envelope,
  Key,
  PersonCircle,
} from "react-bootstrap-icons";
import { auth, googleProvider } from "../../config/firebase";
import {
  createUserWithEmailAndPassword,
  signInWithPopup,
  sendEmailVerification,
  updateProfile,
} from "firebase/auth";
import { Navigate, Link } from "react-router-dom";
import { Loading } from "react-daisyui";
import ErrorMessage from "./error_message";
import Logo from "../../img/logo.png";
import {
  Stack,
  Container,
  InputGroup,
  Form,
  Button,
  Spinner,
} from "react-bootstrap";

export default function Register() {
  //const auth = useAuth();
  //const router = useRouter();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, setUser] = useState(null);
  const [showPass, setShowPass] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [emailResent, setEmailResent] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  function handlePassword(event) {
    let new_pass = event.target.value;
    setPassword(new_pass);

    // regular expressions to validate password
    var lowerCase = /[a-z]/g;
    var upperCase = /[A-Z]/g;
    var numbers = /[0-9]/g;
    if (!new_pass.match(lowerCase)) {
      setErrorMessage("Password should contains lowercase letters!");
    } else if (!new_pass.match(upperCase)) {
      setErrorMessage("Password should contain uppercase letters!");
    } else if (!new_pass.match(numbers)) {
      setErrorMessage("Password should contains numbers also!");
    } else if (new_pass.length < 10) {
      setErrorMessage("Password length should be more than 10.");
    } else {
      setErrorMessage("");
    }
  }

  // callback hell going on here, need to extract some of it out or await
  const signUp = async () => {
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        setWaiting(false);
        const newUser = userCredential.user;
        console.log(newUser);
        updateProfile(newUser, {
          displayName: name,
        }).then(() => {
          sendEmailVerification(newUser).then(() => {
            setUser(newUser);
            setEmailVerified(newUser.emailVerified);
          });
        });
      })
      .catch((err) => {
        setWaiting(false);
        setError(true);
        const errMsg = String(err.message);
        if (errMsg.includes("auth/email-already-in-use")) {
          setErrorMsg("An account already exists with this email");
        } else if (errMsg.includes("auth/weak-password")) {
          setErrorMsg("Choose a different password");
        } else setErrorMsg("An error occured, please try again later");
      });
  };

  const signInWithGoogle = async () => {
    try {
      const newUser = await signInWithPopup(auth, googleProvider);
      setUser(newUser.user);
    } catch (err) {
      console.error(err);
    }
  };

  const resendEmail = async () => {
    //console.log(user);
    sendEmailVerification(user)
      .then(() => {
        setEmailResent(true);
      })
      .catch((err) => {
        setError(true);
        const errMsg = String(err.message);
        console.error(err);
        if (errMsg.includes("auth/too-many-requests")) {
          setErrorMsg("Too many attempts. Try again later");
        } else setErrorMsg("An error occured, try again later");
      });
  };

  if (user && !emailVerified) {
    return (
      <Container className="form-login">
        <div className="mt-5 icon-box">
          <Stack gap={2}>
            <div className="d-flex flex-row justify-content-center">
              <a
                href="/"
                className="flex align-center justify-center items-center gap-x-4 w-full"
              >
                <img alt="" src={Logo} width="70" height="70" />
              </a>
            </div>
            <div className="d-flex flex-row justify-content-center">
              <h4 className="brand-title">
                Quork<span className="brand-dot">.</span>
              </h4>
            </div>
          </Stack>
          <h4 className="d-flex flex-row justify-content-center mt-3">
            Verify Email
          </h4>
          <h6 className="d-flex flex-row justify-content-center">
            Please check your email for an email validation link
          </h6>
          {error ? (
            <ErrorMessage message={errorMsg} />
          ) : emailResent ? (
            <h6 className="d-flex flex-row justify-content-center">
              Please check your inbox
            </h6>
          ) : (
            <h6 className="d-flex flex-row justify-content-center">
              Didn't get an email?{" "}
              <span className="brand-dot" onClick={resendEmail}>
                Click Here
              </span>{" "}
              to resend
            </h6>
          )}
        <div className="mt-2">
          Return to{" "}
          <Link to="/auth/login">
            <span className="text-primary cursor-pointer">sign In</span>
          </Link>
          .
        </div>
        </div>
      </Container>
    );
  }

  if (user && emailVerified) {
    return <Navigate to="/" />;
  }

  return (
    <Container className="form-login">
      <div className="mt-5 icon-box">
      {/* <Auth /> */}
      <form
        onSubmit={(event) => {
          event.preventDefault();
          setWaiting(true);
          signUp();
        }}
      >
        <Stack gap={2}>
          <div className="d-flex flex-row justify-content-center">
            <a
              href="/"
              className="flex align-center justify-center items-center gap-x-4 w-full"
            >
              <img alt="" src={Logo} width="70" height="70" />
            </a>
          </div>
          <div className="d-flex flex-row justify-content-center">
            <h4 className="brand-title">
              Quork<span className="brand-dot">.</span>
            </h4>
          </div>
        </Stack>
        <h4 className="d-flex flex-row justify-content-center mt-3">
          Create an account
        </h4>
        <h6 className="d-flex flex-row justify-content-center">
          Take the first step towards your dream job
        </h6>
        {error && <ErrorMessage message={errorMsg} />}

        <InputGroup size="sm" className="mb-3 mt-5">
          <InputGroup.Text
            id="inputGroup-sizing-name"
            style={{ backgroundColor: "white" }}
          >
            <PersonCircle fill="#570df8" className="ml-3 h-5 w-5" />
          </InputGroup.Text>
          <Form.Control
            aria-label="Small"
            isValid={name.length > 0}
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Introduce your name"
            aria-describedby="inputGroup-sizing-name"
          />
        </InputGroup>

        <InputGroup size="sm" className="mb-3">
          <InputGroup.Text
            id="inputGroup-sizing-email"
            style={{ backgroundColor: "white" }}
          >
            <Envelope fill="#570df8" className="ml-3 h-5 w-5" />
          </InputGroup.Text>
          <Form.Control
            isValid={
              email.length > 0 &&
              email.includes("@") &&
              email.includes(".") &&
              email.split("@").length == 2
            }
            type="email"
            aria-label="Small"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Introduce your e-mail address"
            aria-describedby="inputGroup-sizing-email"
          />
        </InputGroup>

        <InputGroup size="sm" className="mb-3">
          <InputGroup.Text
            id="inputGroup-sizing-pass"
            style={{ backgroundColor: "white" }}
          >
            <Key fill="#570df8" className="ml-3 h-5 w-5" />
          </InputGroup.Text>
          <Form.Control
            isValid={password.length > 7}
            type="password"
            aria-label="Small"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            aria-describedby="inputGroup-sizing-email"
            placeholder="Add a secure password (6 characters)"
          />

        </InputGroup>

        <div className="d-flex justify-content-center">
        {waiting ? (
          <Spinner variant="grow" />
        ) : (
          <Button
            disabled={
              errorMessage.trim() !== "" ||
              password.length < 6 ||
              name.length == 0 ||
              !email.includes("@") ||
              email.length == 0
            }
            type="submit"
            className="button-primary "
            style={{ width: "200px" }}
          >
            Sign Up
          </Button>
        )}
        </div>
        <div className="w-10/12 border-b border-slate-200 h-0 my-2"></div>
        <div className="d-flex justify-content-center">
          <Button onClick={signInWithGoogle} style={{ width: "200px" }}>
            Login with Google <Google fill="#ffffff" />
          </Button>
        </div>
      </form>
      <div className="mt-2">
        Already have an account?{" "}
        <Link to="/auth/login">
          <span className="text-primary cursor-pointer">Sign In</span>
        </Link>{" "}
        now.
      </div>
      </div>
    </Container>
  );
}
