import React, { useRef, useCallback, useState, useEffect } from "react";
import { Card, Button, Modal } from "react-daisyui";
import { ref, onValue, set } from "firebase/database";
import { db } from "../config/firebase";
import { generateUUID } from "../utils/string";
import { Link } from "react-router-dom";

export default function JobDescriptor({ job, user, likedJob, isDashboard, appliedPosition}) {
  const [likeJob, setLikeJob] = useState();
  const refJob = useRef();
  const refJobLiked = useRef();
  const handleShow = useCallback(() => {
    refJob.current?.showModal();
  }, [refJob]);
  const handleShowLiked = useCallback(() => {
    refJobLiked.current?.showModal();
  }, [refJobLiked]);

  useEffect(() => {
    if (likeJob !== null) {
      setLikeJob(likedJob);
    }
  }, [likedJob]);


  const liked = () => {
    set(ref(db, user.uid + "/jobs/" + job.jobReqId), {...job, 'applicationStatus' :{}});
    set(
      ref(
        db,
        user.uid +
          "/jobs/" +
          job.jobReqId +
          "/applicationStatus/interested_" +
          generateUUID()
      ),
      {
        completed: true,
        order: 0,
        stage: "interested",
        when: new Date().toISOString(),
      }
    );
  };

  const applied = () => {
    liked();
    const t = new Date();
    t.setSeconds(t.getSeconds() + 10);
    set(
      ref(
        db,
        user.uid +
          "/jobs/" +
          job.jobReqId +
          "/applicationStatus/applied_" +
          generateUUID()
      ),
      {
        completed: true,
        order: 1,
        stage: "applied",
        when: t.toISOString(),
      }
    );
  };

  return (
    <Card className="shadow-xl w-11/12 border-slate-200">
      <Card.Body>
        <h1 className="text-2xl">
          {job.title} at{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="text-indigo-700"
            href={job.company_url}
          >
            {job.company_name}
          </a>{" "}
        </h1>
        <h2 className="text-sm">{job.location}</h2>
        <div className="overflow-y-auto h-[26rem]">
          <div
            className="p-2"
            dangerouslySetInnerHTML={{ __html: job.job_description }}
          />
        </div>

        {(user !== null && appliedPosition !== true) && 
        <Card.Actions className="justify-end">
          <Button
            disabled={likeJob}
            color="secondary"
            onClick={() => {
              liked();
              handleShowLiked();
              setLikeJob(true);
            }}
          >
            Like
          </Button>
          {likedJob && !isDashboard ? (
            <Button
              color="primary"
              tag="a"
              href={"/dashboard"}
              rel="noopener noreferrer"
            >
              Go To Dashboard
            </Button>
          ) : (
            <Button
              color="primary"
              tag="a"
              href={job.job_url}
              target="_blank"
              rel="noopener noreferrer"
              onClick={handleShow}
            >
              Apply Now
            </Button>
          )}
        </Card.Actions>
          
      }
        {!user && <Card.Actions>
          <Link to="/auth/login">
          <Button color='primary'>
            Log In
          </Button>
        </Link>
          </Card.Actions>}
      </Card.Body>

      <Modal ref={refJob}>
        <Modal.Header className="font-bold">Alert</Modal.Header>
        <Modal.Body>
          Did you apply for {job.title} at {job.company_name}
        </Modal.Body>
        <Modal.Actions>
          <Button
            color="primary"
            onClick={(e) => {
              applied();
            }}
          >
            Yes
          </Button>
          <Button color="secondary">No</Button>
        </Modal.Actions>
      </Modal>

      <Modal ref={refJobLiked}>
        <Modal.Header className="font-bold">Alert</Modal.Header>
        <Modal.Body>You are now following this job</Modal.Body>
        <Modal.Actions>
          <Button color="secondary">Close</Button>
        </Modal.Actions>
      </Modal>
    </Card>
  );
}
