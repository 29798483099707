import React, { useState } from "react";
import {
  Envelope,
  Google,
  Key,
  EyeFill,
  EyeSlash,
} from "react-bootstrap-icons";
import { auth, googleProvider } from "../../config/firebase";
import {
  signInWithPopup,
  signInWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { Navigate, Link } from "react-router-dom";
import { Loading } from "react-daisyui";
import ErrorMessage from "./error_message";
import Logo from "../../img/logo.png";
import { Container, Stack, Form, Button } from "react-bootstrap";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, setUser] = useState(null);
  const [showPass, setShowPass] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [emailResent, setEmailResent] = useState(false);

  const resetState = () => {
    setUser(null);
    setEmailVerified(false);
  };

  const signInWithGoogle = async () => {
    try {
      const user = await signInWithPopup(auth, googleProvider);
      setUser(user.user);
      setEmailVerified(user.user.emailVerified || false);
    } catch (err) {
      console.error(err);
    }
  };

  const resendEmail = async () => {
    //console.log(user);
    sendEmailVerification(user)
      .then(() => {
        setEmailResent(true);
      })
      .catch((err) => {
        setError(true);
        const errMsg = String(err.message);
        console.error(err);
        if (errMsg.includes("auth/too-many-requests")) {
          setErrorMsg(
            "Too many failed attempts. Please reset password or try again later"
          );
        } else setErrorMsg("An error occured, try again later");
      });
  };

  const signIn = async () => {
    setError(false);
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        setWaiting(false);
        const newUser = userCredential.user;
        console.log(newUser);
        if (!newUser.emailVerified) {
          sendEmailVerification(newUser).then(() => {
            setUser(newUser);
            setEmailVerified(false);
          });
        } else {
          setEmailVerified(true);
          setUser(newUser);
        }
      })
      .catch((err) => {
        setError(true);
        const errMsg = String(err.message);
        console.error(err);
        if (errMsg.includes("auth/too-many-requests")) {
          setErrorMsg(
            "Too many failed attempts. Please reset password or try again later"
          );
        } else if (
          errMsg.includes("auth/wrong-password") ||
          errMsg.includes("auth/user-not-found")
        ) {
          setErrorMsg("Incorrect email or password");
        } else setErrorMsg("An error occured try again later");
        setWaiting(false);
        //console.error(err);
      });
  };

  if (user && !emailVerified) {
    return (
      <Container className="form-login">
        <div className="icon-box m-5">
          <Stack gap={2}>
            <div className="d-flex flex-row justify-content-center">
              <a
                href="/"
                className="flex align-center justify-center items-center gap-x-4 w-full"
              >
                <img alt="" src={Logo} width="70" height="70" />
              </a>
            </div>
            <div className="d-flex flex-row justify-content-center">
              <h4 className="brand-title">
                Quork<span className="brand-dot">.</span>
              </h4>
            </div>
          </Stack>
          <h4 className="d-flex flex-row justify-content-center">
            Verify Email
          </h4>
          <h6 className="d-flex flex-row justify-content-center">
            Please check your email for an email validation link
          </h6>
          {error ? (
            <ErrorMessage message={errorMsg} />
          ) : emailResent ? (
            <h6 className="d-flex flex-row justify-content-center">
              Please check your inbox
            </h6>
          ) : (
            <h6 className="d-flex flex-row justify-content-center2">
              Didn't get an email?{" "}
              <span
                style={{ cursor: "pointer" }}
                className="brand-dot"
                onClick={resendEmail}
              >
                Click Here
              </span>{" "}
              to resend
            </h6>
          )}
        
        <div className="mt-2">
          Return to{" "}
          <span className="text-primary cursor-pointer" onClick={resetState}>
            sign In
          </span>
        </div>
        </div>
      </Container>
    );
  }

  if (user && emailVerified) {
    return <Navigate to="/" />;
  }
  // const [user, loading, error] = useAuthState(auth);
  return (
    <Container className="form-login">
      <div className="icon-box m-5">
        {/* <Auth /> */}
        <form
          onSubmit={(event) => {
            event.preventDefault();
            setWaiting(true);
            signIn();
          }}
        >
          <Stack gap={2}>
            <div className="d-flex flex-row justify-content-center">
              <a
                href="/"
                className="flex align-center justify-center items-center gap-x-4 w-full"
              >
                <img alt="" src={Logo} width="70" height="70" />
              </a>
            </div>
            <div className="d-flex flex-row justify-content-center">
              <h4 className="brand-title">
                Quork<span className="brand-dot">.</span>
              </h4>
            </div>
          </Stack>

          <h2>Welcome Back</h2>

          <Form.Group className="mb-3" controlId="login.ControlInput1">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="name@example.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="login.ControlInput1">
            <Form.Label htmlFor="inputPassword5">Password</Form.Label>
            <Form.Control
              type="password"
              value={password}
              placeholder="Your secret Password"
              onChange={(e) => setPassword(e.target.value)}
              id="inputPassword5"
              aria-describedby="passwordHelpBlock"
            />

            {error && <ErrorMessage message={errorMsg} />}
          </Form.Group>

          <div>
            <Link to="/auth/forgot_password">
              <span className="text-primary cursor-pointer ml-auto my-2">
                Forgot Password
              </span>
            </Link>
          </div>
          <div className="d-flex mt-3 flex-row justify-content-center">
            {waiting ? (
              <Button className="login__btn rounded-full w-10/12 bg-neutral font-bold my-2 text-white p-2 cursor-not-allowed ">
                <Loading color="white" />
              </Button>
            ) : (
              <Stack direction={"vertical"} gap={2}>
                <div className="d-flex justify-content-center">
                  <Button
                    type="submit"
                    className="button-primary "
                    style={{ width: "200px" }}
                  >
                    Login with e-mail
                  </Button>
                </div>
                <div className="d-flex justify-content-center">
                  <Button onClick={signInWithGoogle} style={{ width: "200px" }}>
                    Login with Google <Google fill="#ffffff" />
                  </Button>
                </div>
              </Stack>
            )}
          </div>
        </form>
        <div className="mt-3">
          Don&apos;t have an account?{" "}
          <Link to="/auth/register">
            <span className="text-primary cursor-pointer">Register</span>
          </Link>{" "}
          now.
        </div>
      </div>
    </Container>
  );
}
