import React, { useEffect, useState } from "react";

import { signOut } from "firebase/auth";
import { auth } from "../config/firebase";
import { Link, createSearchParams } from "react-router-dom";
import { Button, Container, Nav, NavDropdown, Navbar, Form, FormControl, InputGroup, Badge } from "react-bootstrap";
import { AsyncTypeahead } from "react-bootstrap-typeahead";

function NavigationBar({ user }) {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const [title, setTitle] = useState(params.get("title") || "");
  const [value, setValue] = useState(params.get("locationName") || "");
  
  const [location, setLocation] = useState(params.get("location") || "");
  const [composedLocation, setComposedLocation] = useState({
    label: params.get("locationName") ,
    latitude: params.get('location') ? (params.get('location').includes(',') ? params.get('location').split(',')[0] : null) :null,
    longitude: params.get('location') ? (params.get('location').includes(',') ? params.get('location').split(',')[1] : null) :null,
  })
  const [locations, setLocations] = useState(params.get('location') ? [composedLocation] :[]);

  useEffect(() => {
    console.log(composedLocation);
  }, [composedLocation])

  const logOut = async () => {
    try {
      await signOut(auth);
    } catch (err) {
      console.error(err);
    }
  };

  const brandStyle = {
    flexBasis: 'auto', // Allow the brand to adjust its width automatically
    flexGrow: 0,      // Do not allow the brand to grow
    flexShrink: 1     // Allow the brand to shrink if needed
  };

  const searchStyle = {
    flexBasis: 0,     // Allow the search box to take up the remaining space
    flexGrow: 1,      // Allow the search box to grow to fill the space
    flexShrink: 0     // Do not allow the search box to shrink
  };

  const toggleStyle = {
    flexBasis: 'auto', // Allow the toggle button to adjust its width automatically
    flexGrow: 0,       // Do not allow the toggle button to grow
    flexShrink: 0      // Do not allow the toggle button to shrink
  };

  return (
    <>
      <Navbar bg="light" expand={'xl'} sticky="top">
        <Container>
          <Navbar.Brand href="/" style={brandStyle}>
            <h1 className="brand-title">Quork<span className="brand-dot">.</span></h1>
          </Navbar.Brand>
          <Form  style={searchStyle}>
            <InputGroup size={'sm'}>
              <FormControl
                type="text"
                placeholder="Title"
                aria-label="Introduce job title"
                value={title}
                onChange={e => setTitle(e.target.value)}
              />
              <AsyncTypeahead
                id="basic-typeahead-single"
                style={{ width: "40%" }}
                options={locations}                
                delay={500}
                size="sm"
                onChange={e => {
                  if (e.length > 0) {
                    setLocation(e[0].latitude + "," + e[0].longitude);
                    setValue(e[0].label);
                  } else {
                    setLocation("");
                    setValue("");
                  }
                }}
                ignoreDiacritics
                defaultSelected = {composedLocation.label ? [composedLocation] : []}
                highlightOnlyResult={false}
                onSearch={(query) => {
                  fetch("https://app.quork.me/city_list", {
                    method: "POST",
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ text: query }),
                  }).then(resp => resp.json()).then(data => {
                    data.sort(
                      (a, b) =>
                        b.name.toLowerCase().startsWith(value.toLowerCase()) -
                        a.name.toLowerCase().startsWith(value.toLowerCase())
                    )
                    setLocations(
                      data.map(x => {
                        return {
                          'label': `${x.name}, ${x.admin1_code}`,
                          'latitude': x.latitude,
                          'longitude': x.longitude
                        }
                      })
                    );
                  })
                }}

                placeholder="Location"
              />
              <Link className="ps-2 pe-2 pt-1 outline-primary go-btn" to={`/results?${createSearchParams({
                title: title,
                location: location,
                locationName: value,
              })}`}>
              Go
              </Link>
            </InputGroup>
          </Form>
          <Navbar.Toggle aria-controls="basic-navbar-nav" style={toggleStyle} />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              {user.user != null
                ?
                <>
                  
                  <NavDropdown title={<div style={{display: "inline-block"}}>{user.user.displayName} {user.user.linkedinProfile ? '' : <Badge bg="warning">1</Badge>}</div>} id="basic-nav-dropdown">
                    <NavDropdown.Item className="dropdown-items-link"><Link className="dropdown-items-link-text" to={'/dashboard'}>Dashboard</Link> </NavDropdown.Item>
                    <NavDropdown.Item className="dropdown-items-link"><Link  className="dropdown-items-link-text" to={"/preferences"}>Settings {user.user.linkedinProfile ? '' : <Badge bg="warning">NEW</Badge>}</Link> </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="/" onClick={logOut}>Log Out</NavDropdown.Item>
                  </NavDropdown>
                </>
                :
                <>
                  <Button href="/auth/login" className="button-primary">Sign in/up</Button>
                </>}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavigationBar;
