import React from "react";

export default function ErrorMessage({message}){
    return(
        <div>
            <h6 style={{color:"red"}}>
                {message}
            </h6>
        </div>
    )
}