import './App.css';
import Container from 'react-bootstrap/Container';
import NavigationBar from './components/navigation_bar';
import { auth } from './config/firebase'
import {
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements,
  Route
} from "react-router-dom";
import Home from './pages/home';
import Results from './pages/results';
import { useEffect, useState } from 'react';
import Preferences from './pages/preferences';
import Dashboard from './pages/dashboard';
import Progress from './pages/progress';
import { Theme } from 'react-daisyui';
import Layout from './components/layout/layout';
import AuthLayout from './components/layout/auth_layout';
import { CookiesProvider } from 'react-cookie';
import Terms from './pages/terms';
import Privacy from './pages/privacy';
import About from './pages/about';
import Contact from './pages/contact';
import AI from './pages/ai';
import Login from './pages/auth/login';
import Register from './pages/auth/register';
import ForgotPassword from './pages/auth/forgot_password';
import { getPrefsCallback } from './utils/user_prefs';


function App() {
  const [user, setUser] = useState(null);
  useEffect(() => {
    if (user === null) {
      debugger;
      auth.onAuthStateChanged(x => {
        console.log("reload");
        if (x) {
          getPrefsCallback(x, (data) => {
            if(data.val() === null) {
              setUser({...x});
            }
            else if(data.val().linkedinProfile){

              try {
              
              const dataFetch = async (profile) => {
                const data = await (
                  await fetch("https://app.quork.me/profile?profileid="+profile.linkedinProfile, {
                    method: "POST",
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                    },
                    // body: JSON.stringify({
                    //   profileid: profile.linkedinProfile,
                    // }),
                  })
                ).json();
                const results = data;
                
                console.log(results);  
                setUser({...x, ...profile, profile : results})
              };
        
              dataFetch(data.val());
            } catch(ex) {
              console.log('impossible to retrieve linkedin profile')
            }
              // setUser({ ...x, ...data.val() });
            } 
            setUser({ ...x, ...data.val() });
          })
        }
      })
    }
  }, [user]);

  const router = createBrowserRouter(//[

    createRoutesFromElements(
      <>
        <Route path="/" element={<Layout user={user} />}>
          <Route index element={<Home />} />
          <Route path="dashboard" element={<Dashboard user={user} />} />
          <Route path="results" element={<Results user={user} />} />
          <Route path="preferences" element={<Preferences user={user} />} />
          <Route path="progress" element={<Progress user={user} />} />
          <Route path="terms" element={<Terms />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />
          <Route path="ai" element={<AI />} />
        </Route>
        <Route path="auth/" element={<AuthLayout />}>
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="forgot_password" element={<ForgotPassword />} />
        </Route>
      </>

    )
  )


  return (
    <CookiesProvider defaultSetOptions={{ path: '/' }}>

        <RouterProvider router={router} />

    </CookiesProvider>
  );
}

export default App;
