import React, { useState } from "react";
import { Envelope } from "react-bootstrap-icons";
import { auth } from "../../config/firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import { Link } from "react-router-dom";
import { Loading } from "react-daisyui";
import Logo from "../../img/logo.png";
import {
  Stack,
  Container,
  InputGroup,
  Form,
  Button,
  Spinner,
} from "react-bootstrap";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [waiting, setWaiting] = useState(false);
  const [sent, setSent] = useState(false);

  const sendEmail = () => {
    setWaiting(true);

    sendPasswordResetEmail(auth, email)
      .then(() => {
        //email sent successfully
        setSent(true);
        setWaiting(false);
      })
      .catch((err) => {
        console.log(err);
        console.log(err.code);
        console.log(err.message);
        setWaiting(false);
      });
  };

  if (sent) {
    return (
      <Container className="form-login">
        <div className="mt-5 icon-box">
          <Stack gap={2}>
            <div className="d-flex flex-row justify-content-center">
              <a
                href="/"
                className="flex align-center justify-center items-center gap-x-4 w-full"
              >
                <img alt="" src={Logo} width="70" height="70" />
              </a>
            </div>
            <div className="d-flex flex-row justify-content-center">
              <h4 className="brand-title">
                Quork<span className="brand-dot">.</span>
              </h4>
            </div>
          </Stack>
          <h4 className="d-flex flex-row justify-content-center">Email Sent</h4>
          <h6 className="d-flex flex-row justify-content-center">
            Check your email for a password reset link
          </h6>
        
        <div className="mt-2">
          Return to{" "}
          <Link to="/auth/login">
            <span className="text-primary cursor-pointer">Sign In</span>
          </Link>
          .
        </div>
        </div>
      </Container>
    );
  }
  return (
    <Container className="form-login">
      {/* <Auth /> */}

      <div className="mt-5 icon-box">
        <Stack gap={2}>
          <div className="d-flex flex-row justify-content-center">
            <a
              href="/"
              className="flex align-center justify-center items-center gap-x-4 w-full"
            >
              <img alt="" src={Logo} width="70" height="70" />
            </a>
          </div>
          <div className="d-flex flex-row justify-content-center">
            <h4 className="brand-title">
              Quork<span className="brand-dot">.</span>
            </h4>
          </div>
        </Stack>
        <h4 className="d-flex flex-row justify-content-center">
          Forgot Password
        </h4>

        <form
          className="flex flex-col justify-center items-center w-full"
          onSubmit={(event) => {
            event.preventDefault();
            sendEmail();
          }}
        >
          <InputGroup size="sm" className="mb-3 mt-5">
            <InputGroup.Text
              id="inputGroup-sizing-sm"
              style={{ backgroundColor: "white" }}
            >
              <Envelope fill="#570df8" className="ml-3 h-5 w-5" />
            </InputGroup.Text>
            <Form.Control
              aria-label="Small"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email to be recovered"
              aria-describedby="inputGroup-sizing-sm"
            />
          </InputGroup>

          {waiting ? (
            <Spinner animation={"grow"} />
          ) : (
            <div className="d-flex flex-col justify-center">
            <Button type="submit" className="button-primary ">
              Send Reset Link
            </Button>
            </div>
          )}
        </form>
      
      <div className="mt-2">
        Return to{" "}
        <Link to="/auth/login">
          <span className="text-primary cursor-pointer">Sign In</span>
        </Link>
        .
      </div>
      </div>
    </Container>
  );
}
