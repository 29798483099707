export const endpoint = "http://ai.quork.ph:5055/search";
export const JOBS_ENDPOINT="http://ai.quork.ph:5055/search_solr"
export const LATLNG_ENDPOINT="http://ai.quork.ph:5055/city_list"

export const perks = [
    // "On-site Child Care",
    // "Gym",
    // "Pet Friendly",
    // "4-day Workweek",
    // "Foosball",
    // "Arcade",
    "Flexible Hours",
    // "Ping-pong Table",
    // "Healthcare",
    "Health Insurance",
    "Dental Insurance",
    "Vision Insurance",
    "Life Insurance",
    "Pension and Retirement Plan",
    // "Snacks",
    // "Bagels",
    // "Free Food",
    // "Barista",
    "Volunteering Days",
    // "Donation Matching",
    "Epmployee Discounts",
    "Unlimited Holidays",
    "Paid Time Off",
    "Mental Health Assistance",
    // "Maternity Leave",
    // "Paternity Leave",
    "Parental Leave",
    // "On-Site Mother's Room",    
    "Employee Stock Purchase Plan",
    // "Volunteer Time Off",
    // "Pet Insurance",
    "HSA",
    // "Employee Assistance Program",
    "401k",
    // "On-Site Clinic",
    "Immigration Assistance"
    // "Relocation Bonus",
    // "Tuition Reimbursement"
];

export const perksCat = {
    "WLB": [ // "On-site Child Care",
        // "Gym",
        // "Pet Friendly",
        // "4-day Workweek",
        // "Foosball",
        // "Arcade",
        "Flexible Hours",
        // "Ping-pong Table"],
        "Volunteering Days",
        // "Donation Matching",
        "Employee Discounts",
        "Unlimited Holidays",
        "Paid Time Off",
        // "Maternity Leave",
        // "Paternity Leave",
        "Parental Leave",],
    "Insurance": [
        // "Healthcare",
        "Health Insurance",
        "Dental Insurance",
        "Vision Insurance",
        "Life Insurance",
    ],
    "Finances": [
        "HSA",
        // "Employee Assistance Program",
        "401k",
        "Employee Stock Purchase Plan",
        "Pension and Retirement Plan",
    ],
    "Support": [
        // "On-Site Clinic",
        "Immigration Assistance",
        // "Relocation Bonus",
        // "Tuition Reimbursement,
        "Mental Health Assistance",
    ]
}