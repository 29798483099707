import React, { useCallback, useEffect, useRef, useState } from "react";
import { Send, Chat } from "react-bootstrap-icons";

import {
  Button,
  Form,
  InputGroup,
  Stack,
  Image,
  Spinner,
} from "react-bootstrap";
import { X } from "react-bootstrap-icons";
import TextareaAutosize from "react-textarea-autosize";
import { addChat } from "../utils/job_tracker_recorder";
import GaugeChart from "react-gauge-chart";

function ChatQuork({ job, user }) {
  const assistantName = "Quorker";
  const [disableText, setDisableText] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [history, setHistory] = useState("");
  const [chat, setChat] = useState(`${assistantName}: `);
  const [submitted, setSubmitted] = useState(false);
  const [loadGauge, setLoadGauge] = useState(true);
  const [gaugeValue, setGaugeValue] = useState(user === null ? 120 : 0);
  const chatRef = useRef(chat);
  const inputRef = useRef(null);
  const [chatBubbles, setChatBubbles] = useState(job.chat ? job.chat : []);
  const userName = `${user ? `${user.displayName}` : "User"}`;

  let userSkills = user
    ? `${
        user.skills
          ? userName + " has the following skills: " + user.skills.join(",")
          : ""
      }`
    : "";

  if (user && user.profile && user.profile.skills) {
    const linkedinSkills = user.profile.skills.join(",");
    userSkills = `${userSkills}${
      userSkills == "" ? "" : ", "
    }${linkedinSkills}`;
  }

  const userPerks = user
    ? `${
        user.perks
          ? userName +
            " is interested in companies with: " +
            user.perks.join(",")
          : ""
      }`
    : "";

  let perksAndSkills = [userSkills, userPerks]
    .filter((x) => x !== "")
    .join(" also ");

  if (user && user.profile) {
    let description = "";
    if (user.profile.about !== null) {
      description += userName + " " + user.profile.about;
      description += ". ";
    }
    if (user.profile.education !== null && user.profile.education.length > 0) {
      description +=
        userName + " has studied " + user.profile.education.join(", ") + ". ";
    }

    if (user.profile.languages !== null && user.profile.languages.length > 0) {
      description +=
        userName + " speaks " + user.profile.languages.join(", ") + ". ";
    }

    if (
      user.profile.experience !== null &&
      user.profile.experience.length > 0
    ) {
      description +=
        userName +
        " has been working as: " +
        user.profile.experience.join("; ") +
        ". ";
    }

    perksAndSkills = `${description}${perksAndSkills}`;
  }

  if (perksAndSkills.length > 0)
    perksAndSkills +=
      ". Use this information to validate if " +
      userName +
      " is a good fit or not in this position";

  const setChatRef = (data) => {
    chatRef.current += data;
    setChat(chat + data);

    if (chatBubbles.length === 0)
      chatBubbles[0] = {
        writer: assistantName,
        content: `Hello! My name is ${assistantName}, please feel free to ask me about this job.`,
      };

    let lastBubble = chatBubbles[chatBubbles.length - 1];
    if (lastBubble.writer == assistantName && submitted) {
      lastBubble.content = lastBubble.content + data;
      chatBubbles[chatBubbles.length - 1] = lastBubble;
      setChatBubbles(chatBubbles);
      addChat(user, job, chatBubbles);
    }
  };

  const refMessage = useRef(null);

  useEffect(() => {
    if (chatBubbles.length) {
      try {
        refMessage.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      } catch (ex) {
        console.log("not rendered");
      }
    }
  }, [chatBubbles.length, chatBubbles[chatBubbles.length - 1]]);

  const [loaded, setLoaded] = useState(false);
  const [scoringLoaded, setScoringLoaded] = useState(false);

  const [text, setText] = useState("");
  const [prompt, setPrompt] = useState(
    `This is a conversation between ${userName} and ${assistantName}, a friendly assistant. ${assistantName} is helpful, kind, honest, good at writing, and never fails to answer any requests immediately. ${assistantName} is really concise in the answer.\n${assistantName} is specialist in this job:\n ${job.job_description
      .replace("\n\n", "")
      .replace(
        "\n",
        ""
      )}\n The company that is publishing this job is: \n${(job.company_name
      ? job.company_name
      : job.companyName
    ).replace("\n", "")}\n The position is offered in: ${
      job.location
    }.\n${perksAndSkills}. ${userName} has a score of ${gaugeValue} out of 100 which it is the match for this job.\n\n${userName}: Hello!\n${assistantName}:`
  );

  const chatWithQuork = async (completionParams) => {
    let controller = new AbortController();
    // const response = await fetch('https://quorkserver.asuscomm.com:5001/completion', {
    const response = await fetch("https://ai-quork.ddns.net/completion", {
      method: "POST",
      body: JSON.stringify(completionParams),
      headers: {
        Connection: "keep-alive",
        "Content-Type": "application/json",
        Accept: "text/event-stream",
      },
      signal: controller.signal,
    });

    try {
      // eslint-disable-next-line no-undef
      const reader = response.body
        .pipeThrough(new TextDecoderStream())
        .getReader();
      let text = "";
      while (true) {
        const { value, done } = await reader.read();
        setLoaded(true);
        setSubmitted(false);
        if (done) {
          setDisableText(false);
          let lines = text.split("\n");
          let process = true;
          while (process) {
            if (lines[lines.length - 1] == "") {
              lines.pop();
            } else {
              process = false;
              text = lines.join("\n");
              break;
            }
          }
          setHistory(history + text);
          break;
        }
        try {
          let responseStream = JSON.parse(value.split("data:")[1]);
          text += responseStream.content;
          setChatRef(responseStream.content);
          refMessage.current.scrollIntoView({
            behavior: "instant",
            block: "end",
          });
          var chatHistory = document.getElementById("chatBody");
          if (chatHistory) chatHistory.scrollTop = chatHistory.scrollHeight;
        } catch (err) {}
      }
    } finally {
      controller.abort();
    }
  };

  useEffect(() => {
    if (!disableText && document.getElementById("textarea") !== null) {
      document.getElementById("textarea").focus();
    }
  }, [disableText]);

  useEffect(() => {
    console.log(user);
    if (history === "" && showChat) {
      setHistory(prompt);
    }
  }, [history, showChat]);

  useEffect(() => {
    if (history.endsWith(`${assistantName}:`)) {
      const ai_data = {
        stream: true,
        n_predict: 4600,
        temperature: 0.7,
        stop: ["</s>", `${assistantName}:`, `${userName}:`],
        repeat_last_n: 256,
        repeat_penalty: 1.18,
        top_k: 40,
        top_p: 0.8,
        tfs_z: 1,
        typical_p: 1,
        presence_penalty: 0,
        frequency_penalty: 0,
        mirostat: 0,
        mirostat_tau: 5,
        mirostat_eta: 0.1,
        grammar: "",
        n_probs: 0,
        prompt: history,
      };
      chatWithQuork(ai_data);
    }
  }, [history]);

  function sendQuestion() {
    const question = text;
    if (question.trim() != "") {
      setDisableText(true);
      chatBubbles.push({ writer: userName, content: question.trim() });
      chatBubbles.push({ writer: assistantName, content: "" });
      setSubmitted(true);
      setChatBubbles(chatBubbles);
      if (user !== null) {
        addChat(user, job, chatBubbles);
      }
      setChatRef(`\n${userName}: ${question}\n${assistantName}:`);
      setHistory(
        `${history}\n${userName}: ${question.trim()}\n${assistantName}:`
      );
      setText("");
    }
  }

  function parseString(chat) {
    return chat.replace("\n\n", "<br />").replace("\n", "<br />");
  }

  function BubbleChatAI({ text }) {
    return (
      <div className="d-flex justify-content-start">
        <div className="quork-chat-bubble quork-chat-bubble-ai">
          {text.trim() === "" ? (
            <Spinner animation="grow" size="sm" />
          ) : (
            <div dangerouslySetInnerHTML={{ __html: parseString(text) }}></div>
          )}
        </div>
      </div>
    );
  }

  function BubbleChatHuman({ text }) {
    return (
      <div className="d-flex justify-content-end">
        <div className="quork-chat-bubble quork-chat-bubble-user">{text}</div>
      </div>
    );
  }

  useEffect(() => {
    if (user !== null && user.profile !== undefined && user.profile !== null) {
      if (!scoringLoaded) {
        setScoringLoaded(true);
      } else {
        const fittingPrompt = `### Information about the candidate:\n${perksAndSkills}.\n\n### Information about the job:\n${job.job_description.replace("\n\n")}\n\n### RULES:\nJust answer the value in the format: Score:<VALUE>`;
        //     .replace("\n")}.\nFollow exactly this rules for scoring:\n- if ${userName} has been working previously in similar roles than ${
        //   job.title
        // }, score up to 70 out of 99, if not, score 10.\n- if ${userName} overqualify for this position, then score 10 else score -10\n- if ${userName} has similar skills to the required for this ${
        //   job.title
        // } score ${userName} with up to 20.\nPay attention adding the scores for each section and don't fail doing the addition for each value. Those are the only possible way to score ${userName}.\nApply good criteria, as a person applying for a job without experience cannot have more than 50 in the total score.\n${userName}: What is my score value for this job? I want just the score, nothing else\n${assistantName}:`;

        const ai_data = {
          agentId: "e82075dc-4b5b-430a-9a3d-b38ba3dc602e",
          question: fittingPrompt,
        };
        // let controller = new AbortController();
        const response = fetch(
          "https://coral-app-pcxnk.ondigitalocean.app/api/beta/openai/assistants",
          {
            method: "POST",
            mode: "cors",
            body: JSON.stringify(ai_data),
            headers: {
              Connection: "keep-alive",
              "Content-Type": "application/json",
            },
          }
        );

        response.then((data) => {
          data.json().then((output) => {
            let numberPattern = /\d+/g;
            if (output.response.match(numberPattern) !== null) {
              setGaugeValue(parseInt(output.response.match(numberPattern)[0]) / 100);
              setPrompt(
                prompt.replace(
                  `${userName} has a score of 0`,
                  `${userName} has a score of ${output.response.match(numberPattern)[0]}`
                )
              );
            }
          });
        });
      }
    }
  }, [scoringLoaded, user]);

  return (
    <div>
      <div className="d-flex flex-row justify-content-center">
        <Button
          className="btn-assistant"
          onClick={(e) => {
            setShowChat(!showChat);
          }}
          disabled={gaugeValue === 0}
          title={`Talk to ${assistantName}`}
        >
          Open Assistant <Chat />
        </Button>
      </div>
      <div className="d-flex flex-row justify-content-center mt-2">
        {user !== null ? (
          <>
            {/* {scoringLoaded ? <Spinner variant={'grow'} /> : */}
            <GaugeChart
              style={{ maxWidth: "200px" }}
              id="gauge-chart2"
              nrOfLevels={20}
              colors={["#EA4228", "#F5CD19", "#5BE12C"]}
              textColor="#7278c9"
              needleColor="#F29492"
              percent={gaugeValue}
            />
          </>
        ) : (
          <p>Sign in to get your match</p>
        )}
      </div>
      <div className="d-flex flex-row justify-content-center mt-2">
        <p>Match-Meter</p>
      </div>
      {showChat && (
        <div className="wrapper chat-box">
          <div className="filler-h" style={{ position: "relative" }}>
            <Image
              src="img/logo.png"
              className="quork-chat-logo"
              roundedCircle
              thumbnail
            />
            <div className="d-flex flex-row justify-content-end">
              <X
                size={20}
                onClick={() => setShowChat(!showChat)}
                style={{ cursor: "pointer" }}
              />
            </div>
            <div className="flex-column filler-h chat-text-board">
              <div id="chatBody" className="filler-h chat-text-scroller">
                <Stack gap={3}>
                  {chatBubbles.map((chat) =>
                    chat.writer === assistantName ? (
                      <BubbleChatAI text={chat.content} />
                    ) : (
                      <BubbleChatHuman text={chat.content} />
                    )
                  )}
                </Stack>
                <div ref={refMessage}></div>
              </div>
              <div class="chat-sticky-textbox">
                <InputGroup size="sm" className="mb-3">
                  <TextareaAutosize
                    ref={inputRef}
                    id="textarea"
                    className="form-control"
                    maxRows={4}
                    disabled={disableText}
                    minRows={1}
                    placeholder="type your question"
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key == "Enter") {
                        sendQuestion();
                      }
                    }}
                  />
                  <Button
                    disabled={disableText}
                    variant="outline-secondary"
                    id="button-addon2"
                    onClick={() => sendQuestion()}
                  >
                    <Send />
                  </Button>
                </InputGroup>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ChatQuork;
