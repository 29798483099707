import React from "react";
import { Container, Image, Col, Row } from "react-bootstrap";

export default function Contact() {
    return <Container className="pt-5 pb-5">
<>
    <div class="container px-6 py-12 mx-auto">
        <div class="text-center ">
            <h4 className="brand-title brand-dot">Contact us</h4>

            <h6 class="mt-2 text-2xl font-semibold text-gray-800 md:text-3xl dark:text-white">We’d love to hear from you</h6>

            <p class="mt-3 text-gray-500 dark:text-gray-400"></p>
        </div>
        
        <Image rounded style={{width: "100%"}} src="https://images.unsplash.com/photo-1568992688065-536aad8a12f6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1632&q=100" alt="" />
        
        <Row className="mt-5">
            <Col sm>
                <div className="icon-box svg-box-dimesions">
                <span class="svg-box">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="svg-box">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                    </svg>
                </span>

                <h2 class="mt-4 text-base font-medium text-gray-800 dark:text-white">Tell us what you think</h2>
                <p class="mt-2 text-sm text-gray-500 dark:text-gray-400">Speak to our quorker team.</p>
                <a className="text-indigo-500 hover:cursor-pointer" href="mailto: support@quork.me">support@quork.me</a>
                </div>
            </Col>

            <Col sm >
                <div className="icon-box svg-box-dimesions">
                <span class="svg-box">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="svg-box">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                    </svg>
                </span>
                
                <h2 class="mt-4 text-base font-medium text-gray-800 dark:text-white">Visit us</h2>
                <p class="mt-2 text-sm text-gray-500 dark:text-gray-400">Still remote, but we are in</p>
                <p class="mt-2 text-sm text-indigo-500 dark:text-indigo-400">Walnut Creek, California</p>
                </div>
            </Col>

            <Col sm>
                <div className="icon-box svg-box-dimesions">
                <span class="svg-box">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="svg-box">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                    </svg>
                </span>
                
                <h2 class="mt-4 text-base font-medium text-gray-800 dark:text-white">Call us</h2>
                <p class="mt-2 text-sm text-gray-500 dark:text-gray-400">Mon-Fri from 8am to 5pm.</p>
                <p class="mt-2 text-sm text-indigo-500 dark:text-indigo-400">+1 (925) 391-0423</p>
                </div>
            </Col>
        </Row>
    </div>
</>
    </Container>
}