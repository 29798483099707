import React, { useEffect, useState, useRef } from "react";
import { ref, onValue, set } from "firebase/database";
import { db } from "../config/firebase";
import { useSearchParams } from "react-router-dom";

import { ArrowLeft, GeoAlt, HeartFill, HouseFill } from "react-bootstrap-icons";
import JobDescription from "../components/job_description";
import NotFound from "../components/results/not_found";
import {
  Button,
  Col,
  Container,
  Row,
  Stack,
  InputGroup,
  Image,
  Badge,
  Spinner,
} from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";

function Results({ user }) {
  const [loading, setLoading] = useState(false);
  const [applications, setApplications] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [jobsAll, setJobsAll] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [company, setCompany] = useState("default");
  const [companies, setCompanies] = useState([]);
  const [location, setLocation] = useState("default");
  const [locations, setLocations] = useState([]);
  const [searchCriteria, setSearchCriteria] = useState([]);
  const companyRef = useRef(null);
  const locationRef = useRef(null);

  let [searchParams] = useSearchParams();

  const loadJobs = () => {
    setCompany("default");
    setLocation("default");
    
    if (user !== null) {
      const userRef = ref(db, user.uid + "/jobs");
      onValue(userRef, (snapshot) => {
        let jobs = snapshot.val() ? Object.keys(snapshot.val()) : [];
        setApplications(jobs);
        
      });
    }
  };

  useEffect(() => {
    if (company === "default" && location === "default") {
      setJobs(jobsAll);
      setLocations([...new Set(jobsAll.map((item) => item[0].location))]);
    } else {
      let results = [];
      if (company !== "default") {
        jobsAll.forEach((x) => {
          let extractedCompany = x[0].companyName
            ? x[0].companyName
            : x[0].company_name;
          if (extractedCompany === company) results.push(x);
        });
      }

      if (location !== "default") {
        let toSearch = results.length > 0 ? results : jobsAll;
        results = [];

        toSearch.forEach((x) => {
          if (x[0].location === location) results.push(x);
        });
      }
      setJobs(results);
      setLocations([...new Set(results.map((item) => item[0].location))]);
    }
  }, [company, location]);

  useEffect(() => {
    if (loading && jobs.length === 0) {
      loadJobs();
      const dataFetch = async () => {
        const data = await (
          await fetch("https://app.quork.me/search_solr", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              text: searchParams.get("title"),
              location: searchParams.get("location"),
            }),
          })
        ).json();
        const results = data.results.filter(
          (v, i, a) => a.findIndex((v2) => v2.jobReqId === v.jobReqId) === i
        );
        setJobs(groupByN(1, results));
        setJobsAll(groupByN(1, results));
        setCompanies([
          ...new Set(
            results.map((item) => {
              return item.companyName ? item.companyName : item.company_name;
            })
          ),
        ]);
        setLocations([...new Set(results.map((item) => item.location))]);
        setSearchCriteria(data.search_criteria);

        setLoading(false);
      };

      dataFetch();
    }

    console.log(jobs);
  }, [loading, jobs]);

  useEffect(() => {
    setLoading(true);
    setJobs([]);
    setSelectedJob(null);
  }, [searchParams]);

  let groupByN = (n, arr) => {
    let result = [];
    for (let i = 0; i < arr.length; i += n) result.push(arr.slice(i, i + n));
    return result;
  };

  return (
    <Container>
      {!loading && jobs.length == 0 && (
        <NotFound
          job={searchParams.get("title")}
          location={searchParams.get("locationName")}
        />
      )}

      {selectedJob && (
        <Container className="mt-3">
          <JobDescription
            job={selectedJob}
            isTracked={applications.indexOf(selectedJob.jobReqId) !== -1}
            user={user}
            quit={() => {
              setSelectedJob(null);
            }}
            callback={() => {
              setLoading(true);
              setJobs([]);
              setSelectedJob(null);
            }}
          />
        </Container>
      )}

      {selectedJob === null && (
        <div className={`place-items-center`}>
          {loading && <Spinner animation="grow" />}

          {jobs.length > 0 && (
            <>
              <Row className="mt-5 mb-5 pb-5">
                <Col xs={12} lg={5}>
                  {/* Filter */}
                  <div className="icon-box sticky-top-filter">
                    <Container fluid>
                      <Stack gap={3}>
                        <h5 className="brand-dot">Filters:</h5>

                        <InputGroup size="sm">
                          <Typeahead
                            id="company"
                            size="sm"
                            style={{ width: "85%" }}
                            ref={companyRef}
                            placeholder="Select Company"
                            options={companies.sort((a, b) => b > a)}
                            onChange={(e) => {
                              if (e.length > 0) setCompany(e[0]);
                            }}
                          />
                          <Button
                            variant="outline-secondary"
                            style={{ borderColor: "#d7d7d7", color: "#d7d7d7" }}
                            onClick={() => {
                              setCompany("default");
                              companyRef.current.clear();
                            }}
                          >
                            X
                          </Button>
                        </InputGroup>

                        <InputGroup size="sm">
                          <Typeahead
                            id="location"
                            size="sm"
                            ref={locationRef}
                            style={{ width: "85%" }}
                            placeholder="Select City"
                            options={locations}
                            onChange={(e) => {
                              if (e.length > 0) setLocation(e[0]);
                            }}
                          />
                          <Button
                            style={{ borderColor: "#d7d7d7", color: "#d7d7d7" }}
                            variant="outline-secondary"
                            onClick={() => {
                              setLocation("default");
                              locationRef.current.clear();
                            }}
                          >
                            X
                          </Button>
                        </InputGroup>
                        <small className="fst-italic fw-light small">
                          Showing {jobs.length} jobs
                        </small>
                      </Stack>
                    </Container>
                  </div>
                </Col>
                <Col lg={7}>
                  {/* Results */}

                  <Container>
                    <Stack gap={4}>
                      {jobs.map((x, i) => {
                        return (
                          <>
                            <div
                              key={i}
                              className={`job-box ${i > 5 ? 'reveal fade-bottom' : ''}`}
                              onClick={() => {
                                setSelectedJob(x[0]);
                              }}
                            >
                              <Row>
                                <Col xs={4} lg={2}>
                                  <Container fluid>
                                    <Image
                                      src={x[0].image}
                                      className="job-box-img"
                                      roundedCircle
                                    />
                                  </Container>
                                </Col>
                                <Col xs={8} lg={10}>
                                  <Container className="">
                                    <Stack gap={1}>
                                      <div className="d-flex flex-row">
                                        <h4>{x[0].title} </h4>
                                        {applications.indexOf(x[0].jobReqId) !==
                                          -1 && (
                                          <HeartFill
                                            fill="#4439e0"
                                            className="ms-2"
                                          />
                                        )}
                                      </div>

                                      <p>
                                        {x[0].companyName
                                          ? x[0].companyName
                                          : x[0].company_name}
                                      </p>
                                      <div className="d-flex flex-row">
                                        {x[0].posted_on && (
                                          <div className="primary">
                                          {`Posted on: ${x[0].posted_on.split(" ")[0]}`}
                                        </div>
                                        )}
                                      </div>

                                      <div className="d-flex flex-row job-box-location">
                                        <GeoAlt />
                                        <div className="align-middle">
                                          {x[0].location}
                                        </div>
                                        {x[0].remote && " / Remote allowed"}
                                      </div>
                                    </Stack>
                                  </Container>
                                </Col>
                              </Row>
                            </div>
                          </>
                        );
                      })}
                    </Stack>
                  </Container>
                </Col>
              </Row>
            </>
          )}
        </div>
      )}
    </Container>
  );
}

export default Results;
