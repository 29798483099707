import React, { useState, useRef } from 'react';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Dropdown  from 'react-bootstrap/Dropdown';
import { white_button, flat_button } from '../constants/styles';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { CustomMenu } from './custom_menu_location';


export default function SearchBox() {
    const nav = useNavigate();
    const [locationValue, setLocationValue] = useState({});
    const jobTitle = useRef(null);

  
    return (
        <>
            <style type="text/css">
                {`
                    ${flat_button}
                    ${white_button}
                `}
            </style>
            
            <Container className='w-75'>
            <Card className='p-3 mb-1 bg-white border-0' variant='card-borderless'> 
                <Card.Body>
                    <InputGroup className="mb-3">
                        <Form.Control ref={jobTitle} aria-label="Tile" placeholder='Job Type' />
                        

                        <Dropdown onSelect={(value) => {
                            setLocationValue(JSON.parse(value))
                            }}>
                            <Dropdown.Toggle className='w-40' variant='white' id="dropdown-custom-components">
                            {locationValue.name ? `${locationValue.name}, ${locationValue.admin1_code}` : 'Set Location...'}
                            </Dropdown.Toggle>

                            <Dropdown.Menu as={CustomMenu}>
                            </Dropdown.Menu>
                        </Dropdown>


                        <Button 
                            variant='flat'
                            onClick={(e) => {
                                if(locationValue.name && jobTitle.current.value !== '') {                    
                                    nav({
                                        pathname: '/results',
                                        search: `?${createSearchParams({title: jobTitle.current.value, location: `${locationValue.latitude},${locationValue.longitude}`, locationName: `${locationValue.name}, ${locationValue.admin1_code}`})}`
                                    });
                                }
                            }}>Search</Button>
                    </InputGroup>
                </Card.Body>
            </Card>
            </Container>
        </>
    )
}
