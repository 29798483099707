import React, { useEffect, useState } from "react";
import { perksCat } from "../../constants/perks";
import { key } from "localforage";
import { Col, Container, Row } from "react-bootstrap";

export default function Perks(props) {
  const [selected, setSelected] = useState(new Set(props.prefs.perks));

  // useEffect(() => {
  //     if (props.prefs.perks) {
  //         setSelected(new Set(props.prefs.perks))
  //     }
  //     //console.log(props.prefs.perks)
  // }, [])

  useEffect(() => {
    console.log(selected);
    props.prefs.perks = Array.from(selected);
    props.onEdit(props.prefs);
  }, [selected]);

  const isSelected = (key) => {
    if (selected.has(key)) return true;
    return false;
  };

  const handleChange = (event, key) => {
    //console.log(key)
    if (event.target.checked) {
      setSelected((previousState) => new Set(previousState.add(key)));
    } else {
      setSelected((previousState) => {
        previousState.delete(key);
        return new Set(previousState);
      });
    }
  };

  return (
    <>
        <h4>How is your dream company:</h4>
        <Row className="mt-3">
          <Col sm>
            <h5>Work Life Balance</h5>
            <ul className="grid grid-flow-row lg:grid-cols-4 md:grid-cols-3 grid-cols-2 justify-start w-full gap-y-4 ">
              {perksCat["WLB"].map((perk, index) => {
                return (
                  <li key={perk} className="w-full flex justify-start">
                    <label className="label cursor-pointer w-full flex justify-start">
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          handleChange(e, perk);
                        }}
                        checked={isSelected(perk)}
                        className="checkbox checked:bg-primary checked:text-text-white"
                        id={`${perk}_check`}
                      ></input>
                      <span className="label-text ml-2 w-fit max-w-[75%]">
                        {perk}
                      </span>
                    </label>
                  </li>
                );
              })}
            </ul>
          </Col>
          <Col sm>
            <h5>Insurance</h5>
            <ul className=" grid grid-flow-row lg:grid-cols-4 md:grid-cols-3 grid-cols-2 justify-start w-full gap-y-4 ">
              {perksCat["Insurance"].map((perk, index) => {
                return (
                  <li key={perk} className="w-full flex justify-start">
                    <label className="label cursor-pointer w-full flex justify-start">
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          handleChange(e, perk);
                        }}
                        checked={isSelected(perk)}
                        className="checkbox checked:bg-primary checked:text-text-white"
                        id={`${perk}_check`}
                      ></input>
                      <span className="label-text ml-2  w-fit max-w-[75%]">
                        {perk}
                      </span>
                    </label>
                  </li>
                );
              })}
            </ul>
          </Col>
          </Row>
          <Row>
          <Col sm>
            <h5>Financial Benefits</h5>
            <ul className=" grid grid-flow-row lg:grid-cols-4 md:grid-cols-3 grid-cols-2 justify-start w-full gap-y-4 ">
              {perksCat["Finances"].map((perk, index) => {
                return (
                  <li key={perk} className="w-full flex justify-start">
                    <label className="label cursor-pointer w-full flex justify-start">
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          handleChange(e, perk);
                        }}
                        checked={isSelected(perk)}
                        className="checkbox checked:bg-primary checked:text-text-white"
                        id={`${perk}_check`}
                      ></input>
                      <span className="label-text ml-2  w-fit max-w-[75%]">
                        {perk}
                      </span>{" "}
                    </label>
                  </li>
                );
              })}
            </ul>
          </Col>
          <Col sm>
            <h5>Personal Support</h5>
            <ul className=" grid grid-flow-row lg:grid-cols-4 md:grid-cols-3 grid-cols-2 justify-start w-full gap-y-4 ">
              {perksCat["Support"].map((perk, index) => {
                return (
                  <li key={perk} className="w-full flex justify-start">
                    <label className="label cursor-pointer w-full flex justify-start">
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          handleChange(e, perk);
                        }}
                        checked={isSelected(perk)}
                        className="checkbox checked:bg-primary checked:text-text-white"
                        id={`${perk}_check`}
                      ></input>
                      <span className="label-text ml-2  w-fit max-w-[75%]">
                        {perk}
                      </span>
                    </label>
                  </li>
                );
              })}
            </ul>
          </Col>
        </Row>
      
    </>
  );
}
